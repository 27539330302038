<div class="is-divider is-invisible"></div>
<div class="columns is-centered is-mobile" [id]="'offer-detail-'+offer.ocProduct.gencode">
    <div class="column is-8" [data-cy]="'offer-details-'+offer.ocProduct.gencode">
        <h3 class="is-flex is-vcentered is-justified-between is-uppercase has-text-weight-semibold">
            <span>Description de l'offre</span>&nbsp;
            <span> Montant</span>
        </h3>
        <div class="is-divider"></div>
        <!-- Detail line -->
        <div class="is-flex is-vcentered is-justified-between">
            <span class="is-flex is-vcentered">
                <span class="details-line-label is-block spacer-right-5" [data-cy]="'offer_name'">{{offer.ocProduct.nom}}</span>
                <span *ngIf="offer.ocProduct.eligibilites?.includes(TYPE_ELIGIBILITE.FOYER)" class="is-secondary tag is-inline-block">B.iG</span>
            </span> &nbsp;
            <span class="has-text-weight-semibold" [data-cy]="'price_with_location'">{{(offer.ocProduct.prix.initial + offer.ocProduct.fraisDeLocation).toFixed(2)}} €/mois</span>
        </div>
        <!-- Detail line -->
        <p class="is-flex is-vcentered is-justified-between has-text-info" *ngIf="offer.ocProduct.dureeEngagement">
            <span class="details-line-label info">Engagement de {{offer.ocProduct.dureeEngagement.replace('monthly','')}}
                mois</span>
        </p>
        <p class="is-flex is-vcentered is-justified-between" *ngIf="!offer.ocProduct.dureeEngagement">
            <span class="details-line-label info">Sans engagement</span>
        </p>
        <!-- Detail line -->
        <!-- FMS -->
        <p class="is-flex is-vcentered is-justified-between has-text-weight-semibold" *ngIf="!isFaimUnlimited">
            <span class="details-line-label" data-cy="fai_activation">Frais mise en service</span>
            &nbsp;
            <span *ngIf="offer.ocProduct?.fraisDeMiseEnService" [data-cy]="'price_activation'">{{offer.ocProduct?.fraisDeMiseEnService?.prix}}€</span>
            <span *ngIf="!offer.ocProduct?.fraisDeMiseEnService" [data-cy]="'price_activation'">-</span>
        </p>
        <!-- FMD -->
        <p class="is-flex is-vcentered is-justified-between has-text-weight-semibold" *ngIf="isFaimUnlimited">
            <span class="details-line-label" data-cy="faimUnlimited_installation">Frais mise à disposition</span>
            <span class="is-invisible"></span>
            <span *ngIf="offer.ocProduct?.fraisMiseADisposition" [data-cy]="'price_installation'">{{offer.ocProduct.fraisMiseADisposition.prix}}€</span>
            <span *ngIf="!offer.ocProduct?.fraisMiseADisposition" [data-cy]="'price_installation'">-</span>
        </p>

        <!-- Detail line -->
        <div class="promo-manual-wrapper" *ngIf="applicablePromotions.length">
            <form [formGroup]="formPromo">

                <div class="columns">
                    <div class="column">
                        <div class="field">
                            <div class="control">
                                <input class="is-checkradio" id="promoManual_{{offer.ocProduct.gencode}}" type="checkbox"
                                    [data-cy]="'enable_manual_promo_input'" formControlName="enablePromotions">
                                <label for="promoManual_{{offer.ocProduct.gencode}}"
                                    [data-cy]="'enable_manual_promo'">Activez les remises spéciales</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="columns is-multiline" [ngClass]="{'disabled': formPromo.get('enablePromotions').disabled || isLoadingManualPromo, 'is-loading': isLoadingManualPromo}">
                    <div class="column is-12" *ngFor="let manualPromo of applicablePromotions">
                        <div class="field">
                            <div class="control">
                                <input formControlName="promotion" name="promotion" [value]="manualPromo.id"
                                    [data-cy]="'select_manual_promo_'+manualPromo.id" class="input is-checkradio"
                                    type="radio" id='promoManual_{{manualPromo.id}}-{{offer.ocProduct.gencode}}'>
                                <label for="promoManual_{{manualPromo.id}}-{{offer.ocProduct.gencode}}">
                                    <span class="radio-text" [data-cy]="'manual_promo_name'+manualPromo.id">
                                        {{manualPromo.name}}
                                        <span [data-cy]="'promo_amount_'+manualPromo.id">{{manualPromo.amount}}</span>€
                                        pendant <span [data-cy]="'promo_obligation_'+manualPromo.id">{{manualPromo.duration}}</span>mois
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

            </form>
        </div>

        <!-- RNA for offer with exclu_assos parameters -->
        <div class="is-divider"></div>
        <div *ngIf="offer?.ocProduct.excluAsso" [data-cy]="'rna_bloc'" class="columns is-flex is-aligned-center">
            <div class="column is-4">
                <p class="has-text-weight-semibold">
                    <span class="details-line-label">Vérifiez le numéro RNA de l'association</span>
                </p>
                <span>Chaque association est identifiée par un "numéro RNA"</span>
            </div>
            <div class="column is-4">
                <form [formGroup]="rnaForm">
                    <div class="control has-dynamic-placeholder has-icons-right">
                        <label for="rna">Numéro RNA</label>
                        <input formControlName="rna" name="rna" data-cy="rna_input" class="input"
                            [maxlength]="rnaForm.get('rna')?.value.startsWith('W') ? 10 : 14" tlvFormClass
                            [tlvFormClassErrorControl]="rnaForm.get('rna')">
                        <span class="icon is-small" *ngIf="rnaForm.get('rna')?.touched">
                            <i
                                [ngClass]="{'tri-check-circle has-text-success': rnaForm.get('rna')?.valid && isRnaValid,
                                'tri-exclamation-circle has-text-danger': rnaForm.get('rna')?.invalid && rnaForm.get('rna')?.value}">
                            </i>
                        </span>

                    </div>
                    <p data-cy="rna_invalid" id="rna-error" class="help is-danger" *ngIf="rnaForm.get('rna')?.invalid && rnaForm.get('rna')?.value">
                        Veuillez saisir un RNA valide
                    </p>
                </form>

            </div>

            <div class="column is-4">
                <div class="buttons">
                    <button [disabled]="rnaForm?.invalid || controlRnaInProgress"
                        class="button is-outlined is-primary" data-cy="btn_check_rna" (click)="checkRnaValidity()">
                        <span *ngIf="controlRnaInProgress">Traitement en cours...</span>
                        <span *ngIf="!controlRnaInProgress">Vérifier le RNA</span>
                    </button>
                </div>
            </div>

        </div>

        <!-- Price details -->
        <div class="is-divider is-invisible"></div>
        <!-- Change to ocProduct once the api deliver range -->
        <div class="columns is-justified-between has-text-right" [class.is-multiline]="offer.ocProduct?.gamme === 'bbox_tv_plus'"
            *ngIf="!loader">
            <div class="column is-12" *ngIf="offer.ocProduct?.gamme === 'bbox_tv_plus'">
                <tlv-smart-tvs-list (inputValidatedEvent)="isDisabled = $event"></tlv-smart-tvs-list>
            </div>
            <!-- Equipment Bloc -->
            <div class="column is-flex has-text-left is-aligned-center">
                <img tlvImageLoading class="is-block equipment-img-size" [btlSrc]="productAssociated?.image || offer.ocProduct.image" [alt]="EQUIPMENT_FILTER_LABELS[offer.ocProduct.equipement]">
                <ul class="is-block ml-2">
                    <li *ngFor="let equipment of splitedEquipments">{{equipment}}</li>
                </ul>
            </div>
            <!-- Since the first Promotion api call is trigerred and not called on component init -->
            @if (offer.ocProduct.promotions.length) {
                <div class="column is-narrow">
                    <p class="is-flex has-text-primary has-text-weight-semibold" data-cy="price_with_promo">
                        <span class="is-uppercase">prix remisé :&nbsp;</span> {{
                            ((offer.cart?.promotions?.manual?.length ? offer.fai.prices.final : offer.ocProduct.prix.final)
                            + offer.ocProduct.fraisDeLocation).toFixed(2)
                        }}€/mois
                    </p>
                    <ng-container *ngIf="offer.ocProduct.promotions[0].duree">
                        <p class="is-size-6" data-cy="promo_duration">
                            pendant {{offer.ocProduct.promotions[0].duree}} mois
                        </p>
                        <p class="is-size-6" data-cy="price_without_promo">
                            puis {{((offer.ocProduct.prix.pourToujours) + offer.ocProduct.fraisDeLocation).toFixed(2)}}€ par mois
                        </p>
                    </ng-container>
                    <p class="is-size-6" *ngIf="offer.ocProduct.fraisDeLocation" data-cy="have_location">location box incluse</p>
                </div>
            } @else {
                <div class="column is-narrow" *ngIf="offer.cart.promotions.automatic.length || offer.cart.promotions.manual.length">
                    <p class="is-flex has-text-primary has-text-weight-semibold" data-cy="price_with_promo">
                        <span class="is-uppercase">prix remisé :&nbsp;</span> {{(offer.fai.prices.final +
                            offer.ocProduct.fraisDeLocation).toFixed(2)}}€/mois
                    </p>
                    <ng-container *ngIf="offer.fai.promotions.getDuration()">
                        <p class="is-size-6" [data-cy]="'promo_duration'">
                            pendant {{offer.fai.promotions.getDuration()}} mois
                        </p>
                        <p class="is-size-6" data-cy="price_without_promo">
                            puis {{((offer.fai.prices.forever) + offer.ocProduct.fraisDeLocation).toFixed(2)}}€ par mois
                        </p>
                    </ng-container>
                    <p class="is-size-6" *ngIf="offer.ocProduct.fraisDeLocation" data-cy="have_location">location box incluse</p>
                </div>
            }
            <div class="column is-narrow"
                *ngIf="!offer.ocProduct.promotions.length && !offer.cart.promotions.automatic.length && !offer.cart.promotions.manual.length">
                <p class="has-text-weight-semibold has-text-primary">
                    <span class="is-uppercase">Prix : </span>
                    <span data-cy="price_without_promo">{{(offer.fai.prices.final +
                        offer.ocProduct.fraisDeLocation).toFixed(2)}}€/mois</span>
                </p>
                <p>location box incluse</p>
            </div>
        </div>
        <div class="buttons is-justified-end">
            <button class="button is-primary" [disabled]="!isDisabled || !isRnaValid" data-cy="addToCart"
                (click)="addToCart()">Valider cette offre</button>
        </div>
    </div>
</div>
<div class="is-divider is-invisible"></div>
