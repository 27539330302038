// eslint-disable-next-line max-len
const EMAIL_REGEXP: RegExp = RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'i'); // NOSONAR
const NAME_REGEXP: RegExp = /^(?!\s)[^!"#$%&+/;<=>?`^|~¡¢£¤¥¦§¨©ª«¬®¯°±²³´µ¶•¸¹º»¼½¾¿ÆÐÑÕ×ØÝÞßæ÷øþ]*$/;
const MOBILE_REGEXP: RegExp = /^(06|07)\d{8}$/;
const PHONE_REGEXP: RegExp = /^0[1-9]\d{8}$/;
const DATE_REGEXP = /(0[1-9]|[12]\d|3[01])\/(0[1-9]|1[012])\/(19|20)\d\d$/;
const FAI_REGEX = /^0[123459]\d{8}$/;
const FRENCH_SIMPLE_DATE_REGEXP = /(0[1-9]|[12]\d|3[01])\/(0[1-9]|1[012])\/(19|20)\d\d$/;
const ALPHA_NUM_REGEXP = /^[a-zA-Z0-9]*$/;
const NUMBERS_REGEXP = /^\d*$/;
const POSTAL_ADDRESS_REGEXP: RegExp = /^[a-zA-Z0-9 '`ÀÂÄÇÈÉÊËÎÏÔÖÙÛÜàáâäçèéêëîïôöùûü-]*$/;
const ZIP_CODE_REGEXP: RegExp = /^\d{5}$/;
const SIREN_REGEXP: RegExp = /^\d{9}$/;
const EMAIL_BBOX_REGEXP: RegExp = /^\w+([-+.']\w+)*@?(bbox.fr)$/;
const NOT_EMAIL_BBOX_REGEXP: RegExp = /^\w+(?:[-.]\w+)*@(?!(?:bbox)\.fr$)[\w-]+(?:\.[\w-]+)*\.\w{2,}$/;
const VOIP_REGEXP: RegExp = /^(09)\d{8}$/;

// PWD ALLOWED characters are ASCII Hexa 21 to 7E
const PWD_SPECIAL_CHARACTERS: string = '!"#$%&\'()+,-./:;<=>?@^_`{}|~*\\]\\[\\\\';
const SPECIAL_CHARACTERS: RegExp = new RegExp(`[${PWD_SPECIAL_CHARACTERS}]`);
const REJECTED_CHAR_REGEX: RegExp = new RegExp(`(?![\\w${PWD_SPECIAL_CHARACTERS}]).+`);

export const PWD_RULES = {
    MAX_LENGTH: 20,
    MIN_LENGTH: 8,
    ALPHA_FOLLOW_MAX: 6,
    NUM_FOLLOW_MAX: 6,
    UPPERCASE: /[A-Z]/,
    LOWERCASE: /[a-z]/,
    DIGIT: /\d/,
    SPECIAL_CHARACTERS,
    REJECTED_CHAR_REGEX
};

export const enum FORM_ERROR_KEYS {
    FORBIDDEN_CHAR = 'forbiddenChar',
    REJECTED_STRING = 'rejectedString',
    FOLLOW_APLHA = 'alphaFollow',
    FOLLOW_NUMBER = 'numFollow',
    TOO_YOUNG = 'tooyoung',
    EMAIL_BBOX = 'bbox',
    PASSWORD_COMPLEX = 'complex'
}

export const REGEXS = {
    EMAIL_REGEXP,
    NAME_REGEXP,
    MOBILE_REGEXP,
    PHONE_REGEXP,
    DATE_REGEXP,
    FAI_REGEX,
    ALPHA_NUM_REGEXP,
    NUMBERS_REGEXP,
    FRENCH_SIMPLE_DATE_REGEXP,
    POSTAL_ADDRESS_REGEXP,
    ZIP_CODE_REGEXP,
    SIREN_REGEXP,
    EMAIL_BBOX_REGEXP,
    NOT_EMAIL_BBOX_REGEXP,
    VOIP_REGEXP
};

export const enum QUOTE_TYPES {
    FAI = 'fai',
    FAIM = 'faim',
    PHONE = 'phone',
    DEVICE = 'device',
    BOX_FAIM = 'box_faim',
    PHONE_SOWO = 'phone_sowo'
}

export const PARENTAL_CONTROL_PREMIUM: string[] = [
    '12965',
    '72154083'
];

export const PARENTAL_CONTROL_FREE: string[] = [
    '11148',
    '72150789'
];

export const enum CLIENT_TYPE {
    INDIVIDU = 'INDIVIDU',
    PRO = 'ENTREPRISE'
}

export enum SHIPPING_MODES {
    CLICK_AND_COLLECT = 'CLICK_COLLECT',
    RELAIS_EXPRESS = 'POINT_RELAIS_EXPRESS',
    RELAIS_COLIS = 'POINT_RELAIS',
    LA_POSTE = 'LA_POSTE',
    CHRONOPOST = 'CHRONOPOST',
    INSTALLER = 'INSTALLATEUR',
    DIRECT = 'LIVRAISON_DIRECTE',
    LIVRAISON_RDV = 'LIVRAISON_RDV'
}

export const enum ANGULAR_FORM_STATUS {
    VALID = 'VALID',
    INVALID = 'INVALID',
    PENDING = 'PENDING'
}

export const enum HTTP_ERROR_PREFIX {
    'SUCCESS' = '2',
    'REDIRECT' = '3',
    'CLIENT' = '4',
    'SERVER' = '5'
}

export const OPTION_LOCKED_ID = '12782';

export const enum REINSURANCE_TYPES {
    DEFAULT = 'DEFAULT',
    FAI = 'FAI',
    MOBILE = 'MOBILE'
}

export const enum REINSURANCE_CHOICES {
    FREE_SHIPPING = 'FREE_SHIPPING',
    PROMOTIONS = 'PROMOTIONS',
    SECURE_PAYMENT = 'SECURE_PAYMENT',
    CUSTOMER_SERVICE = 'CUSTOMER_SERVICE',
    TERMINATION_FEES = 'TERMINATION_FEES',
    NETWORK_4G = 'NETWORK_4G',
    CHECKED_REVIEW = 'CHECKED_REVIEW'
}

export const compatibilityEsimCmsPath = '/partials//ec/modal_e_sim_mobiles_compatibles';

