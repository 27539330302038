import { Pipe, PipeTransform } from '@angular/core';
import {TYPE_MARKETING} from '@bytel/bytel-sales';
import {ITYPE_MARKETING} from '@interfaces/products.interface';

@Pipe({
    name: 'canDisplayFlag'
})
export class CanDisplayFlagPipe implements PipeTransform {

    transform(typesMarketing: TYPE_MARKETING[] | ITYPE_MARKETING[] = []): boolean {
        return typesMarketing
            .some(type => [
                TYPE_MARKETING.CONVERGENCE,
                TYPE_MARKETING.MULTILIGNES,
                TYPE_MARKETING.FOYER
            ].includes(type));
    }

}
