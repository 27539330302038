import {CUSTOMER_CATEGORY, Edp, JsonProduct, Plan, Product, QUOTE_CONTEXTS, TYPE_MARKETING} from '@bytel/bytel-sales';
import { BasicObject } from '@common-modules';
import { CatalogType } from '@repositories/sales.repository';
import { ALLOWED_CATEGORIES } from './api/catalog.interface';
import { TYPE_FINANCEMENT } from './funding.interface';
import { PriceTypeEnum } from './api/sales.interface';

export enum PRODUCT_TYPES {
    PHONE = 'phone',
    DEVICE = 'device',
    PLAN = 'plan'
}

export enum ISAPIC_PRODUCT_CATEGORY_TYPES {
    PHONE_SIMPLE = 'phone_simple',
    PHONE = 'phone',
    ACCESSORY = 'accessory',
    ACCESSORY_CONFIGURABLE = 'accessory_configurable'
}

export interface PlanCategoryProducts {
    basic: JsonProduct[];
    premium: JsonProduct[];
}

export interface DisplayCart<T extends Product> {
    edp?: Edp;
    plan?: Plan;
    principal?: T;
    parent?: T;
    childs?: T[];
    odr?: number;
    isRecommended?: boolean;
}

export enum OPTION_STATUS {
    SUBSCRIBED = 'SOUSCRITE'
}

export enum FaiCategoryType {
    OFFRE_GP = 'offre_gp',
    OFFRE_PRO = 'offre_pro',
    OFFRE_OLD = 'offre_old',
    OFFRE_SPECIALE = 'offre_special',
    OFFRE_HISTORIQUE = 'offre_historique',
}

export interface IFilterProductAttr {
    sortByPlanType?: boolean;
    sortByProductName?: string;
    sortByPlan?: number;
    sortByPriority?: BasicObject;
    sortByClientType?: boolean;
}

export enum productTypeLabel {
    'phone' = 'téléphone',
    'device' = 'galet',
    'plan' = 'forfait'
}

export interface IContextualizedProductsParams {
    panier: {
        parcours: {
            type: QUOTE_CONTEXTS.ACQUISITION | QUOTE_CONTEXTS.ACQUISITIONFIX;
            produits: { gencode?: string; catalogue: CatalogType.BYTEL }[];
            estCourant: boolean;
        }[];
        client?: { idPersonne: string } | { categorie: CUSTOMER_CATEGORY.GP | CUSTOMER_CATEGORY.PRO };
    };
}

export enum ISapicConnectivity {
    '5G' = '5g',
    '4G' = '4g',
    '4G+' = '4g+',
}

export interface ISapicFinancementProduit {
    type: TYPE_FINANCEMENT;
    apportInitial: number;
    coutTotalFinancement: number;
    montantAFinancer: number;
    montantInteret: number;
    montantMensualite: number;
    nbrEcheances: number;
    prefere: boolean;
    tAEG: number;

}

enum ISAPIC_DISCOUNT_TYPE {
    ODR = 'ODR',
    FIX = 'FIX',
    PERCENT = 'PERCENT'
};

export interface ISapicProduct {
    blocage?: string;
    aBatterieAmovible?: boolean;
    capaciteBatterie?: number;
    categories: ALLOWED_CATEGORIES[];
    connectivite?: ISapicConnectivity;
    couleurs?: string[];
    dasMembre: string;
    dasTete?: string;
    dasTronc?: string;
    dispoCnc?: boolean;
    eligibilites?: string[];
    enfants?: string[];
    etiquetteAnimCo?: string;
    excluPro: boolean;
    financements?: ISapicFinancementProduit[];
    gencode: string;
    image?: string;
    indiceReparabilite?: number;
    medias?: string[];
    meilleurEnfant: string;
    nom: string;
    marque?: string;
    note: number;
    panierSimule: ISapicPanierSimule;
    prix: {
        initial: number;
        final: number;
        subventionne?: number;
        pourToujours?: number;
        duree?: number;
    };
    promotions: ISapicPromotion[];
    edp_phone?: {
        price: number;
        duration: number;
        amount: number;
    };
    quantite?: number;
    recommande: boolean;
    resolutionPhoto?: string;
    tailleEcran?: string;
    type: TYPE_FINANCEMENT;
    typeSim?: string;
    url?: string;
    etat?: string;
}

export interface ISapicFaiProduct {
    gencode: string;
    type: string;
    nom: string;
    image: string;
    typeSim: string;
    equipement: string;
    dureeEngagement: string;
    excluPro: boolean;
    medias: string[];
    fraisDeLocation: number;
    excluAsso: boolean;
    categories: string[];
    technologie: string;
    typeCategorieFai: FaiCategoryType;
    fraisMiseADisposition: {
        prix: number;
        typePrix: PriceTypeEnum;
        libelleDiffere: string;
    };
    fraisDeMiseEnService: {
        prix: number;
        typePrix: PriceTypeEnum;
    };
    note: number;
    blocage: string;
    url: string;
    couleurs: string[];
    recommande: boolean;
    quantite: number;
    estEnStock: boolean;
    promotions: ISapicFaiProductPromotion[];
    promotionsApplicables: ISapicFaiProductPromotion[];
    prix: {
        initial: number;
        pourToujours: number;
        final: number;
    };
    jeu: string;
    engagement: string;
    typePrix: PriceTypeEnum;
    financements: any[];
    produitPrioritaire: string;
    partenaireCommercial: string;
    gamme: string;
    telephoneAvec: any[];
    ajoutsAutomatiquesFront: string[];
    eligibilites?: string[];
}

enum SapicPromotionAccessType {
    PROSPECT= 'PROSPECT',
    CLIENT = 'CLIENT',
    GP = 'GP',
    PRO = 'PRO'
}

export interface ISapicFaiProductPromotion {
    id: string;
    valeur: number;
    reduction: number;
    type: string;
    typesAcces: SapicPromotionAccessType[];
    estIncitation: boolean;
    estDifferee: boolean;
    identifiant: string;
    duree: number;
    proprietesSupplementaires: {
        typesMarketing: TYPE_MARKETING[];
    };
}

export interface ISapicPromotion{
    id: string;
    type: ISAPIC_DISCOUNT_TYPE;
    duree?: number;
    valeur: number;
    reduction: number;
    estIncitation: boolean;
    estDifferee: boolean;
    typesAcces: TYPE_ACCES[];
    proprietesSupplementaires: ISapicTypesMarketing;
}

export interface ISapicTypesMarketing {
    typesMarketing: ITYPE_MARKETING[];
    fanion: string;
}

export interface ISapicPanierSimule {
    promotions: any[];
    financements: ISapicFinancementProduit[];
    parcours: ISapicParcours[];
}

export interface ISapicParcours {
    estCourant: boolean;
    promotions: ISapicPromotion[];
    produits: ISapicProduct[];
}

export enum ITYPE_MARKETING {
    CONVERGENCE = 'CONVERGENCE',
    MULTILIGNES = 'MULTILIGNES',
    PORTABILITE = 'PORTABILITE',
    CODE_POSTAL_ELIGIBILITE_FIXE = 'CODE_POSTAL_ELIGIBILITE_FIXE',
    SOLDES = 'SOLDES',
    FOYER = 'FOYER',
}

export enum TYPE_ACCES {
    CONVERGENCE = 'CONVERGENCE',
    INCITATION = 'INCITATION',
    CLIENT = 'CLIENT',
    GP = 'GP',
    PRO = 'PRO',
    PORTABILITE = 'PORTABILITE',
    AUTOMATIQUE = 'AUTOMATIQUE',
}

export enum TYPE_ELIGIBILITE {
    FOYER = 'Foyer'
}
