import {
    Component, Input, OnInit
} from '@angular/core';
import { BasicObject } from '@common-modules';
import { Fai, ProductFactory, PromotionModel, QuoteContextFaiModel, TYPE_MARKETING } from '@bytel/bytel-sales';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { FaiEligibilityService } from '@services/fai/fai-eligibility.service';
import {CLASSIFICATION, FAI_TECHNO} from '../../../../../../constants/fai';

@Component({
    selector: 'tlv-fai',
    templateUrl: './fai.component.html',
    styleUrls: ['./fai.component.scss']
})
export class FaiComponent implements OnInit {
    @Input() product: Fai;

    public eligibility: BasicObject;
    public equipment: BasicObject = {};
    public images: string;
    public speedDownLabel: string;
    public speedUpLabel: string;
    public plays: string = 'Internet';
    public hasFiber: boolean = false;
    public isFtth: boolean = false;
    public is4GBox: boolean = false;
    public is5GBox: boolean = false;
    public is5GBoxTv: boolean = false;
    public hasTv = false;
    public techno: string;
    public userAddress: string;
    public veryHighSpeed: boolean = false;
    public isPro = false;
    public promotions: PromotionModel[] = [];

    constructor(
        protected faiEligibilityService: FaiEligibilityService,
        protected cartService: CartTeleSalesService) {
    }

    public ngOnInit(): void {
        this.eligibility = this.faiEligibilityService.currentCart?.eligibility;
        this.techno = this.product.data?.technology?.toLowerCase();
        this.is4GBox = ProductFactory.Is(this.product, 'FaimUnlimited') && this.techno === FAI_TECHNO.BOX_4G;
        this.is5GBox = ProductFactory.Is(this.product, 'FaimUnlimited') && this.techno === FAI_TECHNO.BOX_5G;
        if (this.is5GBox){
            this.is5GBoxTv = this.product.data?.classification === CLASSIFICATION.MEDIUM_TV_XGBOX;
        }
        if (!this.is4GBox && !this.is5GBox) {
            this._setSpeed();
            this._setPlays();
            this.hasFiber = (this.eligibility && +this.eligibility[this.techno]?.debitDownMax >= 1) ||
                this.techno.toUpperCase() === Fai.TECHNOLOGIES.FTTH;
            this.isFtth = (this.eligibility && +this.eligibility[this.techno]?.debitDownMax === 1) ||
                this.techno.toUpperCase() === Fai.TECHNOLOGIES.FTTH;
        }

        this.userAddress = this.faiEligibilityService.currentCart?.eligibility?.installationAddress?.inline;
        this.isPro = this.cartService.cartModel?.isPro;
        this._setPromotionsToDisplay();
    }

    public getQuoteIndex(): number {
        return this.cartService.getQuoteIndexByContext(QuoteContextFaiModel);
    }

    public getProductLabel(product: Fai): string {
        if (this.isPro && product.proLabel) {
            return product.proLabel;
        }
        return product?.technology ? product.name : product?.label;
    }

    private _setSpeed(): void {
        const data = this.product.data;
        const speedDown = this.eligibility && this.eligibility[this.techno]?.debitDownMax;
        const speedUp = this.eligibility && this.eligibility[this.techno]?.debitUpMax;

        if (this.techno.toUpperCase() === Fai.TECHNOLOGIES.FTTH) {
            this.speedDownLabel = data.speed_down;
            this.speedUpLabel = data.speed_up;
            this.veryHighSpeed = true;
        } else {
            this.speedUpLabel = `${speedUp} Mb/s`;

            if (speedDown === 1000) {
                this.speedDownLabel = '1 Gb/s';
                this.veryHighSpeed = true;
            } else {
                this.speedDownLabel = `${speedDown} Mb/s`;
            }
        }
    }

    private _setPlays(): void {

        if (this.product.data.play !== '2P') {
            this.hasTv = true;
            this.plays = `${this.plays} - Tv - Téléphonie`;
        } else {
            this.plays = `${this.plays} - Téléphonie`;
        }
    }

    private _setPromotionsToDisplay(): void {
        const isThereAutoPromoToShow =
            this.product?.promotions?.automatic?.some(promo => this._checkPromoHasTypeMarketing(promo)) || false;
        if (isThereAutoPromoToShow) {
            this.promotions = this.product?.promotions.automatic;
        } else {
            this.promotions = this.product?.promotions?.manual || [];
        }
    }

    private _checkPromoHasTypeMarketing(promo: PromotionModel): boolean {
        return !!promo?.typesMarketing.some(type => [
            TYPE_MARKETING.CONVERGENCE,
            TYPE_MARKETING.MULTILIGNES,
            TYPE_MARKETING.FOYER
        ].includes(type));
    }
}

