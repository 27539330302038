import { Injectable } from '@angular/core';
import { EdpFms, IProductRepositoryInterface, JsonCatalog, JsonProduct, Option, Product, ProductFactory } from '@bytel/bytel-sales';
import { ConfigurationService, Oauth2ResourcesService } from '@common-modules';
import { ALLOWED_CATEGORIES } from '@interfaces/api/catalog.interface';
import { ICategories, ICategory } from '@interfaces/category.interface';
import { TYPE_FINANCEMENT } from '@interfaces/funding.interface';
import { IContextualizedProductsParams, ISapicConnectivity, ISapicFinancementProduit, ISapicProduct } from '@interfaces/products.interface';
import { DisplayEdpFmsOptionModel } from '@models/cart/display-edp-fms-option.model';
import { DisplayOptionModel } from '@models/cart/display-option.model';
import { CategoryModel } from '@models/category.model';
import { CLUSTER, ClusterService } from '@services/cluster.service';
import { AsyncSubject, Observable, forkJoin, of } from 'rxjs';
import { catchError, map, mergeMap, share } from 'rxjs/operators';
import { LINE_TYPE } from '../constants/fai';
import bind from '../helper/decorators/bind';

interface IJsonProdutPricesDetails {
    prices: {
        sowo?: {
            base_price: number;
            price: number;
            old_price: number;
        };
        premium?: {
            base_price: number;
            from: boolean;
            funding?: {
                amount: number;
                duration: number;
            };
            price: number;
        };
    };
}

@Injectable({ providedIn: 'root' })
export class ProductRepository implements IProductRepositoryInterface {

    public static FMS_EDP: string = '72155147';
    public static OPTION_PRO_GENCODE: string = '11775';
    public static LOCKED_PLAN_GENCODE: string = '12782';
    public static MOBILE_RECOVERY_GENCODE: string = 'RMBC000000';
    private _cache: Observable<JsonProduct[]>;
    private _cacheCategories: Observable<CategoryModel[]>;

    constructor(private oauth2Resource: Oauth2ResourcesService,
                private configService: ConfigurationService,
                private clusterService: ClusterService) { }

    public static Clone<T extends Product>(product: T): T{
        return product ? product.clone() : null;
    }

    public getProductByGencode(gencode: string): Observable<Product> {
        return this.getProductsByAttribute([gencode],'gencode').pipe(map((p)=>p.shift()));
    }

    public getProductCategories(): Observable<CategoryModel[]> {
        if (!this._cacheCategories) {
            this._cacheCategories = this.oauth2Resource
                .useSalesApi()
                .ventes()
                .categories()
                .get().pipe(
                    map((data: ICategories) => this._cleanCategoryChoices(data)),
                    map((data: ICategories) => Object.values(data.categories).map(this._createCategoryModel))
                );
        }
        return this._cacheCategories;
    }

    public getCategoriesAndChilds(categoriesCode: string[]): Observable<CategoryModel[]> {
        return this.oauth2Resource
            .useSalesApi()
            .ventes()
            .categories()
            .setParams({codes: categoriesCode.join(',')})
            .get().pipe(
                map((data: ICategories) => this._cleanCategoryChoices(data)),
                map((data: ICategories) => Object.values(data.categories).map(this._createCategoryModel))
            );
    }

    public getContextualizedProducts<T extends Product>(
        paramsQuery: {
            [index: string]: string | number;
        },
        bodyData: IContextualizedProductsParams
    ): Observable<{products: Product[]; raw: ISapicProduct[]; totalCount: number}> {
        return this.oauth2Resource
            .catalogue()
            .produitsContextualises()
            .setParams(paramsQuery)
            .useSalesApi()
            .addCache()
            .post(bodyData)
            .pipe(
                catchError((err) => {
                    console.error(err);
                    return of({ produits: [], nombreTotalProduits: 0 });
                }),
                map((catalog: {produits: ISapicProduct[]; nombreTotalProduits: number}) =>
                    ({
                        raw: catalog.produits,
                        products: catalog.produits
                            .map(this._mapSapicToJsonProduct)
                            .map<T>(this._createProductModel).filter((p=>p)),
                        totalCount: catalog.nombreTotalProduits
                    })
                )
            );
    }


    public getContextualizedProductsOrigin<T>(
        paramsQuery: {
            [index: string]: string | number;
        },
        bodyData: IContextualizedProductsParams
    ): Observable<{produits: T[]; nombreTotalProduits: number}> {
        return this.oauth2Resource
            .catalogue()
            .produitsContextualises()
            .setParams(paramsQuery)
            .useSalesApi()
            .addCache()
            .post(bodyData)
            .pipe(
                catchError((err) => {
                    console.error(err);
                    return of({ produits: [], nombreTotalProduits: 0 });
                }),
                map((res) =>  res)
            );
    }

    public getProducts(): Observable<JsonProduct[]> {
        if (!this._cache) {
            this._cache = this.oauth2Resource
                .ventes()
                .produits()
                .setLocalService()
                .useSalesApi()
                .get()
                .pipe(
                    map((catalog: JsonCatalog) => Object.values(catalog.produits)),
                    map(this._injectAbstractProduct),
                    share({
                        connector: () => new AsyncSubject(),
                        resetOnError: false,
                        resetOnComplete: false,
                        resetOnRefCountZero: false
                    }),
                );
        }
        return this._cache;
    }

    public getProductsByAttribute<T extends Product>(values: string[], attribute: string): Observable<T[]> {
        return values.length ? this.getProducts().pipe(
            map((catalog)=>this._findProductsByAttribute(catalog, attribute, values)),
            map(products => products.map(this._createProductModel).filter((p=>p)))
        ) : of([]);
    }

    public getOptionsSouscriptibles(
        planId: string,
        currentOptions: string[],
        clientType = false,
        univers: string = LINE_TYPE.MOBILE,
        idCorbis?: string
    ): Observable<DisplayOptionModel[]>{
        return forkJoin([
            this.getProductWithDetails(planId),
            this.oauth2Resource
                .addHeaders(idCorbis ? { 'X-Version': '2'} : {})
                .referentiel()
                .offres()
                .optionsSouscriptibles()
                .addCache()
                .post({
                    parcours: 'ACTIVATION',
                    univers,
                    cibleClient: clientType ? 'PRO' : 'GP',
                    offresPanier: {
                        idAbonnement: planId,
                        options: currentOptions
                    },
                    idPanierCommandeFixe: idCorbis
                }).pipe(
                    map((data: any) => data.offresSecondaires ? data.offresSecondaires.map((offre)=>offre.idOption) : []),
                    mergeMap((gencodes)=>this.getProductsByAttribute<Option>(gencodes,'gencode'))
                )
        ]).pipe(
            map(([planDetails, optionsDetails]) =>
                optionsDetails
                    .filter((opt)=>planDetails.upSellsProducts.some(upSell=>upSell.gencode === opt.gencode))
                    .map((opt)=>this._createDisplayOptionModel(opt, planDetails)))
        );
    }


    // Don't use this directly, see productDetails.reposiroty
    public getProductWithDetails(gencode: string): Observable<JsonProduct> {
        return this.oauth2Resource
            .setLocalService()
            .addCache()
            .useSalesApi()
            .ventes()
            .produits(gencode)
            .addCache()
            .get();
    }

    private _findProductsByAttribute(cache: JsonProduct[], attribute: string, values: string[]): JsonProduct[] {
        return values.reduce((acc,value)=>acc.concat(cache.filter((cachedProduct)=>cachedProduct[attribute] === value)),[]);
    }

    @bind
    private _injectAbstractProduct(products: JsonProduct[]): JsonProduct[]{
        const edpProduct = products.find((p: JsonProduct) => p.gencode === 'EPD0000000');
        if (!edpProduct) {
            products.push({
                auto_add_front: '', duration: 0, edp_price: 0, max_qty: 0, price: 0,
                name: 'Facilité de paiement du téléphone',
                ecotax: 0,
                entity_id: 0,
                type_id: 'edp',
                type: 'edp',
                sku: 'edp',
                qty: 0,
                gencode: 'EPD0000000',
                position: null,
                iconInfos: { name: '' },
                final_price: 1,
                in_stock: true,
                url: '',
                url_key: ''
            } as any);
        } else {
            edpProduct.gencode = 'edp';
        }
        return products;
    }

    @bind
    private _createProductModel<T extends Product>(product: JsonProduct): T {
        if (product.gencode === ProductRepository.OPTION_PRO_GENCODE){
            product.exclu_pro = true;
        }
        if (product.gencode === ProductRepository.FMS_EDP){
            product.type_id = 'edpFms';
        }
        if (product.hasOwnProperty('first_page')){
            product.firstPage = product.first_page;
        }
        if (product.hasOwnProperty('sim_type')) {
            product.is_esim_compatible = ['hybride', 'esim'].includes(product?.sim_type);
        }
        if (product?.type_id === 'qr_esim') {
            product.isESim = true;
        }
        return ProductFactory.GetInstance<T>(product);
    }

    @bind
    private _createCategoryModel(cat: ICategory): CategoryModel {
        return new CategoryModel({
            id: cat.id,
            name: cat.name,
            description: cat.description,
            code: cat.code,
            metaTitle: cat.meta_title,
            metaKeywords: cat.meta_keywords,
            metaDescription: cat.meta_description,
            products: cat.products,
            subtitle: cat.subtitle,
            sliderPosition: cat.slider_position,
            image: cat.image,
            imageMobile: cat.image_mobile,
            url: cat.url,
            children: cat.children.map(this._createCategoryModel)
        });
    }

    private _createDisplayOptionModel(opt: Product, planDetails: JsonProduct): DisplayOptionModel | DisplayEdpFmsOptionModel {
        if (ProductFactory.Is(opt,EdpFms)){
            return new DisplayEdpFmsOptionModel(
                opt.data,
                planDetails.upSellsProducts.some(upSell=>upSell.gencode === opt.gencode),
                planDetails.upSellsProducts.find(upSell=>upSell.gencode === opt.gencode)?.position || 0
            ) as DisplayOptionModel;
        }
        return new DisplayOptionModel(
            opt.data,
            planDetails.upSellsProducts.some(upSell=>upSell.gencode === opt.gencode),
            planDetails.upSellsProducts.find(upSell=>upSell.gencode === opt.gencode)?.position || 0
        );
    }

    private _cleanCategoryChoices(data: ICategories): ICategories {
        if (!this.clusterService.userIsInCluster(CLUSTER.BANQUE)) {
            data.categories = data.categories.filter(category => category.code !== ALLOWED_CATEGORIES.PROMPTO_TELESALES);
        }

        if ((this.configService.data_refconf?.clusters?.PILOTE_PANIERMIXTE?.length &&
                !this.clusterService.userIsInCluster(CLUSTER.PILOTE_PANIERMIXTE))
            || this.clusterService.userIsInCluster(CLUSTER.BANQUE)) {
            data.categories = data.categories.filter(category => category.code !== ALLOWED_CATEGORIES.SIMOFAI_TELESALES);
        }

        return data;
    }

    @bind
    private _mapSapicToJsonProduct(product: ISapicProduct): JsonProduct {
        const funding = product.financements.find(f=>f.prefere && f.type === TYPE_FINANCEMENT.EDP);
        // const initialDeposit = funding?.apportInitial ?? 0;
        // const sumODRDiscounts = product.promotions.reduce((sum, { reduction, type }) => {
        //     if (type !== 'ODR') {
        //         return sum;
        //     }
        //     return sum + reduction;
        // }, 0);
        // const priceWithOdr = initialDeposit - sumODRDiscounts;
        // const price = priceWithOdr < 1 ? 1 : priceWithOdr;

        // const hasCashbackOffer = product.promotions.some(({ type }) => type === 'ODR') && initialDeposit > 1;

        const colors = product?.couleurs?.includes(null) ? [''] : (product?.couleurs );
        const connectivityMatcher = {
            [ISapicConnectivity['5G']]: '5g',
            [ISapicConnectivity['4G']]: '4g',
            [ISapicConnectivity['4G+']]: '4g+',
        };
        const connectivity = product.connectivite ? connectivityMatcher[product.connectivite] : undefined;

        return {
            name: product.nom,
            gencode: product.gencode,
            entity_id: 0,
            product_ids: product.enfants,
            type_id: product.type,
            exclu_pro: product.excluPro,
            battery_removable: product.aBatterieAmovible,
            battery_capacity: product.capaciteBatterie,
            categories: product.categories,
            max_connection: connectivity,
            color: colors,
            sim_type: product.typeSim,
            sar: product.dasTete,
            trunk_sar: product.dasTronc,
            limb_sar: product.dasMembre,
            anim_co: product.etiquetteAnimCo,
            manufacturer: product.marque,
            note_reparation: product.indiceReparabilite,
            price: product.prix.initial,
            final_price: funding?.coutTotalFinancement ?? product.prix.final,
            prices: null,
            image: product.image,
            small_image: product.image,
            imageHD: product.image,
            medias: product.medias,
            url: product.url,
            state: product.etat,
            in_stock: product.quantite > 0,
            max_qty: 1, // Set by product factory from each type
            ...(funding ? {
                edp_phone: {
                    amount: funding.montantMensualite,
                    duration: funding.nbrEcheances,
                    price: funding.apportInitial
                }
            } : {}),
            ...(this._generatePriceFromJsonProduct(product) ?? {prices: null}),
        } as any;

    }

    private _generatePriceFromJsonProduct(product: ISapicProduct): IJsonProdutPricesDetails | null {
        if (product.prix.final === 1) {
            return null;
        }
        const sowo = {
            base_price: product.prix.initial,
            price: product.prix.initial,
            old_price: product.prix.initial
        };
        const edpFunding: ISapicFinancementProduit = product.financements.find(f=>f.type === TYPE_FINANCEMENT.EDP);
        const premium = {
            base_price: product.prix.initial,
            from: !!edpFunding,
            ...(edpFunding ? {funding: {
                amount: edpFunding.apportInitial,
                duration: edpFunding.nbrEcheances
            }} : {}),
            price: edpFunding ? edpFunding.apportInitial : product.prix.final
        };
        return {prices: {sowo, premium}};
    }

}


