import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, NgZone, OnChanges, OnDestroy, Output } from '@angular/core';
import { GMapsConfig } from '@common-modules';
import { Delivery, Product, QuoteContextAcquisitionModel, QuoteContextFaiModel } from '@bytel/bytel-sales';
import {
    ShippingMethodsModalComponent
} from '@components/checkout/step/delivery/delivery-methods/shipping-methods-modal/shipping-methods-modal.component';
import { IStoreCheckout } from '@interfaces/store-checkout.interface';
import { AddressModel } from '@models/cart/address.model';
import { DeliveryAppointmentModel } from '@models/cart/delivery-appointment.model';
import { DeliveryInfoModel } from '@models/cart/delivery-info.model';
import { SlotModel } from '@models/cart/slot.model';
import { CategoryWithProductModel } from '@models/category.model';
import { OrderModel } from '@models/order/order.model';
import { DialogService } from '@ngneat/dialog';
import { CatalogService } from '@services/catalog.service';
import { AppointmentService } from '@services/checkout/appointment.service';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { DeliveryService } from '@services/checkout/delivery.service';
import { SalesForceService } from '@services/salesforce.service';
import { CalendarEvent } from 'angular-calendar';
import { forkJoin, Observable, of, Subject, Subscription} from 'rxjs';
import { catchError, concatMap, map, mergeMap, tap } from 'rxjs/operators';
import { SHIPPING_MODES } from 'src/app/constants/delivery';
import { ClickCollectModalComponent } from './click-collect-modal/click-collect-modal.component';
import { SimService } from '@services/checkout/sim.service';
import {IconsulterPolitiqueTarifairePaniersApresVente, POLITIQUE_TARIFAIRE} from '@interfaces/delivery.inteface';

interface DeliveryStoresInfo {
    [index: string]: {
        info: DeliveryInfoModel;
        stores: IStoreCheckout[];
    };
}

@Component({
    selector: 'tlv-delivery-methods',
    templateUrl: './delivery-methods.component.html',
    styleUrls: ['./delivery-methods.component.scss']
})
export class DeliveryMethodsComponent implements OnChanges, OnDestroy {
    @Output()
    public submitEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output()
    public editAddress: EventEmitter<void> = new EventEmitter<void>();
    @Input()
    public address: AddressModel;
    @Input()
    public isLoading: boolean = false;

    public availableDelivery: CategoryWithProductModel<Delivery>[];
    public deliveryInfo: DeliveryStoresInfo = {};
    public catSelected: string;
    public ready: boolean = false;
    public selectedMethod: Delivery;
    public selectedMethodSimo: Delivery;
    public SHIPPING_ICONS: {[index: string]: string} = {
        CLICK_COLLECT:'tri-stopwatch-click-collect',
        POINT_RELAIS_EXPRESS:'tri-parcel-relay',
        POINT_RELAIS:'tri-parcel-relay',
        LA_POSTE:'tri-truck-speed',
        CHRONOPOST:'tri-truck-speed',
        INSTALLATEUR:'tri-truck',
        LIVRAISON_DIRECTE:'tri-home',
        LIVRAISON_RDV:'tri-calendar'
    };
    public currentStore;
    public deliveryStore: {[index: string]: any};
    public stores;
    public mapConfig: GMapsConfig = {
        center: {
            lat: 12,
            lng: 12
        },
        height: '585',
        mapTypeId: 'roadmap',
        width: '100%',
        zoom: 13
    };
    public obsSlots: Observable<CalendarEvent<SlotModel>[]>;
    public hasAppointment: boolean = true;
    public errorsCalendarSubject: Subject<string> = new Subject<string>();
    public currentSlot: DeliveryAppointmentModel;
    public currentAppointment: DeliveryAppointmentModel;
    public orderRecovery: OrderModel;
    public isQuoteMixed: boolean = false;
    public hasESimInCart: boolean = false;

    private _subscriptions: Subscription[] = [];

    constructor(private readonly catalogService: CatalogService,
                private readonly cartTeleSalesService: CartTeleSalesService,
                private readonly dialogService: DialogService,
                private readonly deliveryService: DeliveryService,
                private readonly appointmentService: AppointmentService,
                private salesForceService: SalesForceService,
                private zone: NgZone,
                private simService: SimService,
                @Inject(DOCUMENT) private document: any) {
        this.deliveryService.loadStoreAndSlot();
        this.currentStore = this.deliveryService.selectedStore;
        this.currentAppointment = this.deliveryService.selectedAppointment;
        this.orderRecovery = this.salesForceService.prefilledInfo.order;
        this.hasESimInCart = this.simService.hasESimInCart(this.cartTeleSalesService.cartModel);
    }

    public moreInfo(): void {
        this.dialogService.open(ShippingMethodsModalComponent, {
            data: {
                availableShippingMethods: Object.values(this.deliveryInfo).map((obj)=>obj.info),
                selectedMethod: this.selectedMethod
            }
        });
    }

    public ngOnDestroy(): void {
        this._subscriptions.forEach(sub => sub.unsubscribe());
    }

    public ngOnChanges(dataChange): void {
        this.isQuoteMixed = this.cartTeleSalesService.isQuoteMixed();
        if (dataChange.address) {
            this._subscriptions.push(forkJoin([
                this.catalogService.getCategoriesAndChilds<Delivery>(['shippings_telesales'], true),
                this.deliveryService.getDeliveryAvailable(this.cartTeleSalesService.cartModel, this.address),
                this.deliveryService.consulterPolitiqueTarifairePaniersApresVente()
            ]).pipe(mergeMap(([
                [categorie], deliveries, politiqueTarifairePanierApresVente
            ]) =>
                this._getAvailableDeliveriesWithStore(deliveries, categorie)
                    .pipe(map(deliveriesWithStore => [
                        categorie, this._addStoresDeliveryAddressPin(deliveriesWithStore), politiqueTarifairePanierApresVente
                    ]))
            )).subscribe((data: [CategoryWithProductModel<Delivery>,DeliveryStoresInfo,IconsulterPolitiqueTarifairePaniersApresVente]) => {
                this.zone.run(() => {
                    const [
                        categorie, deliveriesWithStore, politiqueTarifairePanierApresVente
                    ] = data;
                    if (this.orderRecovery) {
                        this._filterDeliveriesFromRecovery(deliveriesWithStore, politiqueTarifairePanierApresVente);
                        this.salesForceService.setFms(politiqueTarifairePanierApresVente);
                    } else {
                        if (this.isQuoteMixed && !this.hasESimInCart) {
                            this.deliveryInfo = this._filterDeliveriesFromContext(deliveriesWithStore, QuoteContextFaiModel);
                            const deliveryInfoSimo = this._filterDeliveriesFromContext(deliveriesWithStore, QuoteContextAcquisitionModel);
                            this.selectedMethodSimo = Object.values(deliveryInfoSimo)[0].info.product;
                        } else {
                            this.deliveryInfo = deliveriesWithStore;
                        }
                    }

                    this.availableDelivery = categorie.children.filter(c =>
                        c.products.filter((p) => Object.keys(this.deliveryInfo).includes(p.gencode)).length
                    );

                    if (this.orderRecovery) {
                        this._selectCategoryFromRecovery();
                        return;
                    }

                    const currentDelivery = this.cartTeleSalesService.cartModel.getQuote().getProductByType(Delivery);

                    if (currentDelivery) {
                        this.catSelected = categorie.children.find(c => c.products.find(p => p.gencode === currentDelivery.gencode)).code;
                        this._setCurrentAppointmentStore();
                        this.selectShippingMethod(currentDelivery);
                    } else {
                        this._resetAllParams();
                        if (this.availableDelivery.length === 1 && Object.values(this.deliveryInfo).length === 1) {
                            this.changeCat(this.availableDelivery[0]);
                        }
                    }

                });
            }));
        }
    }

    public selectShippingMethod(shippingMethod: Delivery,noPush: boolean = true): void {
        this.selectedMethod = shippingMethod;
        if (shippingMethod.gencode === SHIPPING_MODES.LIVRAISON_RDV) {
            this._resetSlots();
            this._loadSlots();
            this.ready = false;
        } else if (!this.deliveryInfo[shippingMethod.gencode]?.stores?.length){
            if (noPush){
                this._subscriptions.push(
                    this._addShippingToCart().subscribe(()=>{
                        this.ready = true;
                    })
                );
            } else {
                this.ready = true;
            }
        } else {
            this.stores = this.deliveryInfo[shippingMethod.gencode].stores;
            if (!this.currentStore) {
                this._selectDefaultStore();
                return;
            }
        }
    }

    public openClickCollectModal(): void {
        this.dialogService.open(ClickCollectModalComponent);
    }

    public selectStore(store: IStoreCheckout): void {
        if (store.deliveryType === 'POINT_ADRESSE_LIVRAISON') {
            this.ready = false;
        } else {
            this.deliveryService.setStore(store);
            this.currentStore = store;
            if (this.selectedMethod.gencode !== SHIPPING_MODES.LIVRAISON_RDV) {
                this.ready = true;
            }
            this._subscriptions.push(
                this._addShippingToCart().subscribe()
            );
        }
    }

    public selectSlot(slot: CalendarEvent<DeliveryAppointmentModel>): void {
        if (slot) {
            this.deliveryService.setSlot(slot.meta);
            this._subscriptions.push(
                this._addShippingToCart().subscribe(()=>{
                    this.ready = true;
                })
            );
        }
    }

    public editAdress(): void {
        this.editAddress.emit();
    }

    public changeCat(cat: CategoryWithProductModel): void {
        this.catSelected = cat.code;
        if (this.selectedMethod) {
            this._subscriptions.push(
                this.cartTeleSalesService.removeProduct(this.selectedMethod).subscribe()
            );
        }
        this._resetAllParams();
        this._setShippingOnSingleMethod(cat);
    }

    public submit(): void {
        this.submitEvent.emit();
    }

    private _addShippingToCart(): Observable<Product>{
        this.isLoading = true;
        if (this.isQuoteMixed) {
            return this.cartTeleSalesService.addProduct(this.selectedMethod,
                this.cartTeleSalesService.getQuoteIndexByContext(QuoteContextFaiModel))
                .pipe(
                    concatMap( () => this.cartTeleSalesService.addProduct(this.selectedMethodSimo,
                        this.cartTeleSalesService.getQuoteIndexByContext(QuoteContextAcquisitionModel))),
                    tap(() => this.isLoading = false)
                );
        } else {
            return this.cartTeleSalesService.addProduct(this.selectedMethod).pipe(tap(() => this.isLoading = false));
        }
    }

    private _loadSlots(): void {
        this.obsSlots = this.appointmentService.getDeliveryAppointements(this.address).pipe(
            catchError(() => {
                this.hasAppointment = false;
                return of(null);
            })
        );
    }

    private _addStoresDeliveryAddressPin(deliveriesWithStores: DeliveryStoresInfo): DeliveryStoresInfo {
        Object.keys(deliveriesWithStores)
            .filter((key: string) =>  !!Object.values([
                SHIPPING_MODES.RELAIS_COLIS,
                SHIPPING_MODES.RELAIS_EXPRESS,
                SHIPPING_MODES.CLICK_AND_COLLECT,
            ]).map(k => k.toString()).includes(key))
            .forEach(key => {
                deliveriesWithStores[key].stores
                    .push(this.deliveryService.generateStoreFromDeliveryAddress(this.address));
            });
        return deliveriesWithStores;
    }

    private _setShippingOnSingleMethod(cat: CategoryWithProductModel): void {
        const currentAvailableDelivery:  string[] = cat.productsGencode.filter((pg: string) => Object.keys(this.deliveryInfo).includes(pg));
        if (currentAvailableDelivery?.length === 1) {
            this.selectShippingMethod(
                Object.values(this.deliveryInfo).find(deliInfo => deliInfo.info.gencode === currentAvailableDelivery[0])?.info.product
            );
        }
    }

    private _resetAllParams(): void {
        this.deliveryService.setStore(null);
        this.currentStore = null;
        this.currentAppointment = null;
        this.selectedMethod = null;
        this.stores = null;
        this.deliveryService.setSlot(null);
        this._resetSlots();
        this.ready = false;
    }

    private _resetSlots(): void {
        this.obsSlots = of([]);
        this.hasAppointment = true;
    }

    private _getAvailableDeliveriesWithStore(deliveries: DeliveryInfoModel[], categorie: CategoryWithProductModel<Delivery>):
    Observable<DeliveryStoresInfo> {
        const deliveryGencodeAvailableInCategorie = categorie.children
            .reduce((acc,cat)=>[
                ...acc,
                ...cat.productsGencode
            ],[]);
        deliveries = deliveries.filter((delivery)=>
            deliveryGencodeAvailableInCategorie.includes(delivery.gencode)
        );
        return forkJoin(
            deliveries.map((delivery: DeliveryInfoModel)=>
                // eslint-disable-next-line max-len
                this.deliveryService.getStoreForDelivery(delivery.product, this.address.lat, this.address.lng, this.cartTeleSalesService.cartModel)
                    .pipe(map((stores)=>({stores,delivery})))
            )).pipe(map(deliveriesWithStores=>deliveriesWithStores
            .filter((deliveryWithStores)=>
                deliveryWithStores.stores === null || deliveryWithStores.stores.length
            )
            .reduce((acc,deliveryWithStores)=> (
                {...acc,[deliveryWithStores.delivery.gencode]:{info : deliveryWithStores.delivery,stores:deliveryWithStores.stores}}
            ),
            {})
        ));
    }

    private _setCurrentAppointmentStore(): void {
        this.currentStore = this.deliveryService.selectedStore ?? null;
        this.currentAppointment = this.deliveryService.selectedAppointment ?? null;
    }

    private _selectDefaultStore(): void {
        this.currentStore = this.stores[0];
    }

    private _filterDeliveriesFromContext(deliveriesWithStore: DeliveryStoresInfo, quoteContext): DeliveryStoresInfo {
        return Object.keys(deliveriesWithStore)
            .filter(
                gencode => deliveriesWithStore[gencode].info.idVirtuel === this.cartTeleSalesService.getQuoteIndexByContext(quoteContext)
            )
            .reduce((acc, currentKey) => ({
                ...acc,
                [currentKey]: deliveriesWithStore[currentKey]
            }), {});
    }

    private _filterDeliveriesFromRecovery(
        deliveriesWithStore: DeliveryStoresInfo,
        politiqueTarifairePanierApresVente: IconsulterPolitiqueTarifairePaniersApresVente): void {
        const salesforceData = this.salesForceService.prefilledInfo;

        const offreLivraison = salesforceData.order.offer[0].elementsCommandes
            .find( (elementCommande) => elementCommande.type === 'OFFRE_LIVRAISON' );

        const elementCommandeModifiableLivraison = politiqueTarifairePanierApresVente.elementsCommandesModifiables
            .find((elementCommandeModifiable) => elementCommandeModifiable.id === offreLivraison.id);

        const minPrice = elementCommandeModifiableLivraison.politiqueTarifaire === POLITIQUE_TARIFAIRE.PRIX_INFERIEUR_OU_EGAL_COMPTANT
            ? this.orderRecovery.cart.delivery.deliveryInfos.price
            : 0;

        this.deliveryInfo = Object.keys(deliveriesWithStore)
            .filter(
                gencode => deliveriesWithStore[gencode].info.price <= minPrice
            )
            .reduce((acc, currentKey) => ({
                ...acc,
                [currentKey]: deliveriesWithStore[currentKey]
            }), {});
    }

    private _selectCategoryFromRecovery(): void {
        const cartFromRecovery: CategoryWithProductModel<Product> =
            this.availableDelivery?.find(del => del?.products?.map(prd => prd?.gencode)
                .includes(this.orderRecovery.cart.delivery?.deliveryInfos?.gencode));
        if (this.orderRecovery.cart.delivery?.store) {
            const store = this.deliveryInfo[this.orderRecovery.cart.delivery?.deliveryInfos?.gencode]
                .stores?.find(st => st?.id === this.orderRecovery.cart.delivery?.store?.id);
            if (store){
                this.currentStore = store;
                this._scrollToStore(store);
            }
        }

        if (cartFromRecovery) {
            this.catSelected = cartFromRecovery.code;
            this._setShippingOnSingleMethod(cartFromRecovery);
        }
    }

    private _scrollToStore(store: IStoreCheckout): void {
        const storeElm: HTMLElement = this.document.defaultView.document.querySelector(`#store-${store.id} .store`);
        if (!storeElm){
            return;
        }

        // To scroll on the right position after the next cycle
        window.setTimeout(() => {
            const storeElmHeight: number = this.document.defaultView.document.querySelector(`#store-${store.id} .store`).clientHeight;
            this.document.defaultView.document.querySelector('.stores-container').scrollTo({
                top: store.listIndex * storeElmHeight,
                behavior: 'smooth'
            });
        }, 0);
    }

}
