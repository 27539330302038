import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'priceFormat'
})

export class PriceFormatPipe implements PipeTransform {

    public transform(price: number | string): number | string {

        if (isNaN(Number(price))) {
            return 0;
        }

        return Number.isInteger(Number(price)) ? Number(price) : Number(price).toFixed(2);
    }
}
