import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrismeLoggerDirective } from '@directives/prisme-logger.directive';
import { AutocompleteDirective } from '@directives/autocomplete.directive';
import { FormClassDirective } from '@directives/form-class.directive';
import { InputOnlyNumberDirective } from '@directives/inputOnlyNumber.directive';
import { IsVisibleDirective } from '@directives/is-visible.directive';
import { DataCyDirective } from '@directives/data-cy.directive';
import { FilterObjPipe } from '../pipes/filterObj.pipe';
import { SortObjectByPipe } from '../pipes/sort-object-by.pipe';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeroTitleComponent } from '@components/shared/hero-title/hero-title.component';
import { LoaderComponent } from '@components/shared/loader/loader.component';
import { UrlPictureDirective } from '@directives/image-url.directive';
import { ButtonComponent } from '../trg-components/button/button.component';
import { OptionsComponent } from '../trg-components/options/options.component';
import { PriceComponent } from '../trg-components/price/price.component';
import { RatingComponent } from '../trg-components/rating/rating.component';
import { OrderByPipe } from '../pipes/order-by.pipe';
import { SimTypeLabelPipe } from '../pipes/sim-type-label.pipe';
import {CanDisplayFlagPipe} from '../pipes/can-display-flag.pipe';


const COMMON_COMPONENTS = [
    LoaderComponent,
    HeroTitleComponent,
    OrderByPipe,
    SimTypeLabelPipe
];

const COMMON_UTILS = [
    PrismeLoggerDirective,
    AutocompleteDirective,
    FormClassDirective,
    SortObjectByPipe,
    FilterObjPipe,
    DataCyDirective,
    IsVisibleDirective,
    InputOnlyNumberDirective,
    UrlPictureDirective
];

const COMMON_MODULES = [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule
];

@NgModule({
    declarations: [
        ...COMMON_COMPONENTS, ...COMMON_UTILS, CanDisplayFlagPipe
    ],
    imports: [
        ...COMMON_MODULES, ButtonComponent, OptionsComponent, PriceComponent, RatingComponent
    ],
    exports: [
        ...COMMON_COMPONENTS, ...COMMON_UTILS, ...COMMON_MODULES, ButtonComponent, OptionsComponent, PriceComponent,
        RatingComponent, CanDisplayFlagPipe
    ]
})
export class SharedModules { }
