<header class="has-background-white container-fluid">
    <div class="header-layout">
        <div class="tlv-logo has-background-grey-light">
            <h1 class="has-text-centered">
                <span class="is-hidden" aria-hidden="false">Logo Bouygues Télécom</span>
                <span class="icon is-large"><i class="tri-logo"></i></span>
            </h1>
        </div>
        <div class="header-info" *ngIf="showMenuLinks && !checkRoute()">
            <ul class="columns is-vcentered-desktop is-marginless">
                <ng-container *ngFor="let menuLink of menuLinks">
                    <li *ngIf="menuLink.urlFragment else simpleLink" (click)="onMenuClick(menuLink)" [data-cy]="'menu-'+menuLink.dataCy" [class.has-text-grey]="isActive(menuLink)" class="column is-narrow popover is-popover-right has-cursor" [ngClass]="{'menu-disabled': menuLink.disabled}" [routerLink]="[menuLink.link]" [fragment]="menuLink.urlFragment">
                        <span class="icon is-medium">
                            <i [ngClass]="menuLink.icon"></i>
                        </span>
                        <div class="popover-content">
                            <p>{{menuLink.label}}</p>
                        </div>
                    </li>
                    <ng-template #simpleLink>
                        <ng-container *ngIf="menuLink.isExternal; else routerLink">
                            <li class="column is-narrow popover is-popover-right has-cursor" [ngClass]="{'menu-disabled': menuLink.disabled}" [data-cy]="'menu-'+menuLink.dataCy" [class.has-text-grey]="isActive(menuLink)">
                                <a class="has-text-tertiary" [href]="menuLink.link" rel="noopener noreferrer" target="_blank">
                                    <span class="icon is-medium">
                                        <i [ngClass]="menuLink.icon"></i>
                                    </span>
                                    <div class="popover-content">
                                        <p>{{menuLink.label}}</p>
                                    </div>
                                </a>
                            </li>
                        </ng-container>
                        <ng-template #routerLink>
                            <li class="column is-narrow popover is-popover-right has-cursor" [ngClass]="{'menu-disabled': menuLink.disabled}" [data-cy]="'menu-'+menuLink.dataCy" (click)="onMenuClick(menuLink)"  [class.has-text-grey]="isActive(menuLink)" [routerLink]="[menuLink.link]">
                                <span class="icon is-medium">
                                    <i [ngClass]="menuLink.icon"></i>
                                </span>
                                <div class="popover-content">
                                    <p>{{menuLink.label}}</p>
                                </div>
                            </li>
                        </ng-template>
                    </ng-template>
                </ng-container>
                <li class="column is-flex is-vcentered-desktop is-positionned-right">
                    <span><strong>{{customer?.gender}} {{customer?.lastname}} {{customer?.firstname}}</strong></span>
                </li>
                <li *ngIf="notificationsList.length > 0" class="column is-narrow is-flex is-vcentered">
                    <a class="icon is-small has-status is-ancestor has-text-tertiary" (click)="toggleNotificationsPanel()" data-cy="notifications-open-modal">
                        <span class="tri-bell" aria-hidden="true">
                        </span>
                        <span *ngIf="unreadNotifications.length > 0"
                            class="total-notification is-animating icon is-circled is-descendant is-size-8 has-text-weight-bold has-text-white"
                            [ngClass]="[notificationClass]"
                                data-cy="notifications-length">
                            {{unreadNotifications.length}}
                        </span>
                    </a>
                </li>
                <li class="column is-narrow has-text-right is-paddingless">
                    <button data-cy="close-call-btn" class="button is-primary" (click)="closeCall()">
                        <span class="icon-and-text">
                            <span class="icon is-medium icon-rotation">
                                <i class="tri-phone-outgoing-call"></i>
                            </span>
                            <span>Terminer session</span>
                          </span>
                    </button>
                </li>
            </ul>
        </div>
        <div class="header-info" *ngIf="!IsEnvProd && checkRoute()">
            <ul class="columns is-vcentered-desktop is-marginless">
                <ng-container *ngFor="let privateMenuLink of privateMenuLinks">
                    <li (click)="onMenuClick(privateMenuLink)" [data-cy]="'menu-'+privateMenuLink.dataCy" [class.has-text-grey]="isActive(privateMenuLink)" class="column is-narrow popover is-popover-right has-cursor" [routerLink]="[privateMenuLink.link]" [fragment]="privateMenuLink.urlFragment">
                        <span class="icon is-medium">
                            <i [ngClass]="privateMenuLink.icon"></i>
                        </span>
                        <div class="popover-content">
                            <p>{{privateMenuLink.label}}</p>
                        </div>
                    </li>
                </ng-container>
            </ul>
        </div>
        <span class="user-logout has-cursor is-narrow has-background-success has-text-centered has-text-white" (click)="logout()">
            <span class="icon is-small"><i class="tri-power"></i></span>
            <p class="is-size-6 has-text-weight-medium clip-username">{{salesUser?.username}}</p>
        </span>
    </div>

</header>

<tlv-notifications-panel *ngIf="displayNotificationsPanel" (closeModal)="toggleNotificationsPanel()"></tlv-notifications-panel>
