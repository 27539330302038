<div data-cy="summary-order-modal" class="box is-shadowless">
    <div class="box-content">
        <div class="columns is-multiline is-marginless is-centered revert-order" data-cy="recap-command-modal">
            <div *ngIf="isLoading;else orderSummary" class="column is-12 has-text-centered">
                <span class="icon is-large is-rotating"><i class="tri-logo"></i></span>
                <span>Chargement en cours...</span>
            </div>

            <ng-template #orderSummary>
                <div class="column is-12">
                    <p class="title is-3 has-text-centered">Récapitulatif de commande</p>  
                    <div *ngIf="orderSummaryMessage" id="recap" data-cy="summary-order-msg" [innerHTML]="orderSummaryMessage" class="column is-12 is-size-6">
                    </div>
                    <p *ngIf="error" class="is-warning has-text-weight-medium is-flex">
                        <span class="icon-and-text">
                            <span class="icon is-small is-warning">
                            <i class="tri-exclamation-circle" aria-hidden="true"></i>
                            </span>
                            <span>Une erreur est survenue lors du chargement du récapitulatif de commmande.</span>
                        </span>
                    </p>
                </div>
            </ng-template>

            <button data-cy="finish-payment-btn" class="button is-primary" [disabled]="isLoading" (click)="createOrder()">{{isLoading ? 'Traitement en cours...' : 'Mettre fin à l\'appel et commander'}}</button>
        </div>

        
    </div>
</div>