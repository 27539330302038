import {ChangeDetectorRef, Component, forwardRef, Input, OnDestroy, OnInit} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, Validators, FormControl, FormGroup } from '@angular/forms';
import { Option, Plan, Product} from '@bytel/bytel-sales';
import { OrderModel } from '@models/order/order.model';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { OptionsAvailableService } from '@services/checkout/options-available.service';
import { SalesForceService } from '@services/salesforce.service';
import { forkJoin, Observable, ReplaySubject, Subscription } from 'rxjs';
import { debounceTime, mergeMap } from 'rxjs/operators';

import bind from '../../../../../helper/decorators/bind';

@Component({
    selector: 'tlv-parental-control',
    templateUrl: './parental-control.component.html',
    styleUrls: ['./parental-control.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ParentalControlComponent),
            multi: true
        }
    ]
})
export class ParentalControlComponent implements ControlValueAccessor, OnDestroy, OnInit {
    @Input() public quoteIndex: number;
    public plan: Plan;
    public isFai: boolean = false;
    public waitForCartEdit: boolean = null;
    public parentalControlOptions: Option[];
    public parentalControlForm: FormGroup = new FormGroup({
        choiceControl : new FormControl<boolean | null>(null, [Validators.required]),
        parentalControl : new FormControl<string | null>(null, [Validators.required])
    });
    public premiumParentalControlGencodes: string[];
    public freeParentalControlGencodes: string[];
    public parentalOptionFromRecovery: Option;

    private selectedOption: Option = null;

    private _orderRecovery: OrderModel;
    private _subscriptions: Subscription[] = [];
    private _onChangeSubject: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

    constructor(
        private cartService: CartTeleSalesService,
        private optionsAvailableService: OptionsAvailableService,
        private cdr: ChangeDetectorRef,
        private salesForceService: SalesForceService
    ) {
    }

    public ngOnInit(): void {
        this._orderRecovery = this.salesForceService.prefilledInfo.order;
        this._subscriptions.push(
            this.cartService.refreshObs.subscribe((cart)=> {
                this.plan = cart.getQuote(this.quoteIndex)?.getProductByType(Plan);
                this._setFormValue();
            }),
            this.optionsAvailableService.update$.subscribe((options: Record<string, Option[]>) => {
                this._loadParentalControlOptions(options[this.plan.gencode] ?? []);
                this._addParentalControlFromRecovery();
                this._setFormValue();
            }),
            this.parentalControlForm.statusChanges.pipe(debounceTime(200)).subscribe(this._setValueFromStatus),
        );
        this.isFai = this.cartService.cartModel.getQuote(this.quoteIndex)?.isAcquisitionFix();
        this.plan = this.cartService.cartModel.getQuote(this.quoteIndex)?.getProductByType(Plan);
        this.premiumParentalControlGencodes = this.optionsAvailableService.getParentalControlGencode('premium');
        this.freeParentalControlGencodes = this.optionsAvailableService.getParentalControlGencode('basic');
        this._setFormValue();
    }

    public updateParentalControlOp(option: Option): void {
        this.waitForCartEdit = true;
        const obs: Array<Observable<Product>> = [];
        if (this.selectedOption && option.gencode !== this.selectedOption?.gencode) {
            obs.push(this.cartService.removeProduct(this.selectedOption, this.quoteIndex)
                .pipe(mergeMap(() => this.cartService.addProduct(option, this.quoteIndex)))
            );
        } else if (!this.selectedOption) {
            obs.push(this.cartService.addProduct(option, this.quoteIndex));
        }

        if (!obs.length) {
            this.waitForCartEdit = null;
            return;
        }

        forkJoin(obs)
            .subscribe({
                next: () => {
                    {
                        this.selectedOption = this.cartService.cartModel.getQuote(this.quoteIndex)
                            .getProductsByType(Option)
                            .find((p)=>p.gencode === option.gencode);
                    }
                },
                complete: () => {
                    this.waitForCartEdit = null;
                }
            });

    }

    public removeParentalControlOp(): void {
        this.waitForCartEdit = true;
        this.parentalOptionFromRecovery = null;
        this.cartService.removeProduct(this.selectedOption, this.quoteIndex)
            .subscribe({
                next: () => {
                    this._setFormValue();
                    this.selectedOption = null;
                },
                complete: () => {
                    this.waitForCartEdit = null;
                }
            });
    }

    public writeValue(): void {
        return null;
    }

    public registerOnChange(fn: any): void {
        this._onChangeSubject.subscribe(fn);
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public ngOnDestroy(): void {
        this._subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }

    private _addParentalControlFromRecovery(): void {
        this.parentalOptionFromRecovery = this.parentalControlOptions.find(op =>
            this._orderRecovery?.products?.map(o => o.gencode).includes(op.gencode)
        );

        if (!this.parentalOptionFromRecovery) {
            return;
        }

        const isOptionNotInQuote: boolean = !this.cartService.cartModel.getQuote(this.quoteIndex).products
            .find(p => p.gencode === this.parentalOptionFromRecovery.gencode);
        if (isOptionNotInQuote) {
            this.updateParentalControlOp(this.parentalOptionFromRecovery);
        }
    }

    private onTouched: () => void = (): void => null;

    @bind
    private _setValueFromStatus(): void {
        this._onChangeFormValues(this.parentalControlForm.getRawValue());
        this.onTouched();
        this._onChangeSubject.next(this.parentalControlForm.valid ? this.parentalControlForm.get('choiceControl').value : null);
        this.cdr.detectChanges();
    }

    private _onChangeFormValues(values: {choiceControl: boolean; parentalControl: string | boolean}): void {
        const parentalControlField = this.parentalControlForm.get('parentalControl');
        if (!values.choiceControl && parentalControlField.enabled) {
            parentalControlField.disable({emitEvent:false});
            parentalControlField.reset();
            if (this.selectedOption) { this.removeParentalControlOp(); }
        } else if (values.choiceControl){
            if (parentalControlField.disabled) {
                parentalControlField.enable({emitEvent:false});
            }
            if (!values.parentalControl) {
                this.parentalControlForm.patchValue({parentalControl: this._getDefaultOptionGencode()},
                    {emitEvent: values.parentalControl !== false});
            } else {
                const currentParentalC: Option = this.parentalControlOptions.find(opt => opt.gencode === values.parentalControl);
                const isOptionInCart: boolean = !!this.cartService.cartModel
                    .getQuote(this.quoteIndex).products.find(p => p.gencode === currentParentalC.gencode);

                if (currentParentalC && !isOptionInCart){
                    this.updateParentalControlOp(currentParentalC);
                }
            }
        }
    }

    private _getDefaultOptionGencode(): string | boolean{
        if (this.isFai) {
            return false;
        }
        const defaultOp: Option = this.parentalControlOptions?.find((option: Option) => [
            ...this.optionsAvailableService.getParentalControlGencode('premium'),
            ...this.optionsAvailableService.getParentalControlGencode('basic')
        ].includes(option.gencode));
        return defaultOp?.gencode ?? null;
    }

    @bind
    private _loadParentalControlOptions(options: Option[]): void {
        const opts = options.filter((opt) => [
            ...this.optionsAvailableService.getParentalControlGencode('premium'),
            ...this.optionsAvailableService.getParentalControlGencode('basic')
        ].includes(opt.gencode));
        opts.sort((a,b) => a.position - b.position);
        this.parentalControlOptions = this.plan ? opts : [];
        this._setFormValue();
    }

    private _setFormValue(): void {
        this.selectedOption = this.cartService.cartModel.getQuote(this.quoteIndex)?.getProductsByType(Option)
            .find((option: Option) => this.parentalControlOptions?.map(p => p && p?.gencode).includes(option.gencode));
        this.parentalControlForm.setValue({
            choiceControl: !!this.selectedOption || (this.isFai && !!this.parentalControlOptions?.length),
            parentalControl: this.selectedOption?.gencode || this._getDefaultOptionGencode()
        });
    }
}
