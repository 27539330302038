import { Model } from '../model';
import { ORDER_STATUS, PRINCIPAL_OFFER_TYPE, PRINCIPAL_OFFER_TYPE_QUOTE_MIXED } from '../../constants/order';
import { OffresAchetee } from '@interfaces/api/commande.interface';
import { Type } from 'class-transformer';
import { OrderFaiInfoModel } from '@models/order/order-fai-info.model';
import { OrderCartInfoModel } from './order-cart-info.model';
export class OrderModel extends Model{

    public static readonly DISPLAYABLE_ELEMENTS: string[] = [
        'OFFRE_PRINCIPALE',
        'OFFRE_PRODUIT',
        'OFFRE_OPTION',
        'OFFRE_LIVRAISON',
        'ETALEMENT_PAIEMENT',
        'ESIM'
    ];
    public idPanierRetours: string;
    public version: string;
    public reusable: boolean;
    public id: string;
    public status: ORDER_STATUS;
    public orderCreation: Date;
    public statusChangeReason: string;
    public canal: string;
    public acteurCreateur: string;
    public offer: OffresAchetee[];
    public mainOfferType: string;
    public products: {gencode: string; name: string}[] = [];
    public customerEmail: string;
    public mainPhoneNumber: string;
    public initialPaymentData: {
        transformToHybrid?: boolean;
        paymentRetrival?: boolean;
    };
    @Type(()=>OrderFaiInfoModel)
    public fai: OrderFaiInfoModel;
    @Type(()=>OrderCartInfoModel)
    public cart: OrderCartInfoModel;
    public checkSum: number;
    public personId: string;

    constructor(data: Partial<OrderModel>){
        super(data);
        if (!data){return;}
        this.products = data.offer.reduce((acc,offer)=>
            acc.concat(
                offer.elementsCommandes
                    .filter((el)=>OrderModel.DISPLAYABLE_ELEMENTS.includes(el.type))
                    .map((el)=>({
                        gencode: el.gencode || el.noOffre || (el.type === 'ETALEMENT_PAIEMENT' ? 'EPD0000000' : el.gencode),
                        name: el.libelleCommercial
                    }))
            )
        ,[]);
        this.mainOfferType = this._getPrincipalOfferType(data.offer);
        if (this.id){
            this.checkSum = OrderModel._luhn_caclulate(this.id);
        }
    }

    private static _luhn_checksum(code: string): number {
        const len = code.length;
        const parity = len % 2;
        let sum = 0;
        for (let i = len - 1; i >= 0; i--) {
            // eslint-disable-next-line radix
            let d = parseInt(code.charAt(i));
            if (i % 2 === parity) { d *= 2; }
            if (d > 9) { d -= 9; }
            sum += d;
        }
        return sum % 10;
    }

    private static _luhn_caclulate(partcode: string): number {
        const checksum = OrderModel._luhn_checksum(partcode + '0');
        return checksum === 0 ? 0 : 10 - checksum;
    }

    private _getPrincipalOfferType(offres: OffresAchetee[]): string{
        const types: {label: string; priority: number}[] = [];
        const typesQuote = [];
        for (const offer of offres) {
            if (Object.keys(PRINCIPAL_OFFER_TYPE).includes(offer.type)) {
                let label = PRINCIPAL_OFFER_TYPE[offer.type];
                if (label === PRINCIPAL_OFFER_TYPE.ABONNEMENT_NU && offer?.dureeEngagement) {
                    label = PRINCIPAL_OFFER_TYPE.SIMO;
                }
                types.push({label,priority: Object.values(PRINCIPAL_OFFER_TYPE).indexOf(label)});
            }
        }
        if (types.length >= 2) {
            Object.keys(types).forEach(
                (key) => Object.values(PRINCIPAL_OFFER_TYPE_QUOTE_MIXED).includes(types[key].label) ? typesQuote.push(types[key].label) : ''
            );
        }

        types.sort((a, b) => a.priority - b.priority);
        if (types.length > 0) {
            if (typesQuote.length >= 2) {
                return PRINCIPAL_OFFER_TYPE.MIXED;
            }
            return types[0].label;
        }
        return null;
    }

}
