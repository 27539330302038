import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IEnvEph, INodeState } from '@interfaces/settings.interface';
import { SettingsModel } from '@models/settings';
import { Observable, catchError, map, of } from 'rxjs';
import { ConfigurationService } from '../common-modules/services/configuration.service';


@Injectable({ providedIn: 'root' })
export class SettingsRepository {

    constructor(private httpClient: HttpClient, private configurationService: ConfigurationService) { }

    public getEnvironmentsEph(): Observable<SettingsModel> {

        const projectPath = 'portailvente/ecommerce/vente-assistee/telesales';

        const query = `query {
            project(fullPath: "${projectPath}") {
                environments(search: "review/") {
                nodes {
                    id
                    name
                    state
                    externalUrl
                    createdAt
                    updatedAt
                }
            }
            }
        }`;

        return this.httpClient.post(
            'https://gitlab.int.nbyt.fr/api/graphql',
            JSON.stringify({ query }),
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'PRIVATE-TOKEN': this.configurationService.data_refconf.token_gitlab
                })
            }
        ).pipe(
            map(this._generateSettingsModel),
            catchError(() => of(null))
        );

    }

    private _generateSettingsModel(envsEph: IEnvEph): SettingsModel {
        const externalUrlList = envsEph.data.project.environments.nodes
            .filter(node => node.state === INodeState.Available && !!node.externalUrl)
            .map(node => node.externalUrl);
        return new SettingsModel({ environmentsEphUrl: externalUrlList });
    }

}
