import { Injectable } from '@angular/core';
import { Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ConfigurationService } from '@common-modules';
import { CustomerProGpDetailsModel } from '@interfaces/customer.interface';
import { FaiAddressModel } from '@models/fai/fai-address';
import { FaiCartModel } from '@models/fai/fai-cart.model';
import { FaiEligibilityModel } from '@models/fai/fai-eligibility.model';
import { FaiLogementModel } from '@models/fai/fai-logement.model';
import { FaiLoginsAndVoipsModel } from '@models/fai/fai-portability-logins-voips.model';
import { FaiTechnoModel } from '@models/fai/fai-techno.model';
import { FaiVerticalStructureModel } from '@models/fai/Fai-vertical-structure.model';
import { FaiRepository } from '@repositories/fai.repository';
import { FaiStorage } from '@repositories/storages/fai.storage';
import { CustomerService } from '@services/customer/customer.service';
import { classToPlain, plainToClass } from 'class-transformer';
import { Observable, of } from 'rxjs';
import { delayWhen, filter, map, mergeMap, tap } from 'rxjs/operators';

import { ALLOWED_TYPES } from '@components/fai-widget/eligibility/eligibility.component';
import { CancelOrderModalComponent } from '@components/proposals/cancel-order-modal/cancel-order-modal.component';
import bind from '@helper/decorators/bind';
import { IFaiComplement } from '@interfaces/api/fai-webshop.interface';
import {
    IAddressFull, IFaiAdresseIFainstallation,
    IFaiHousing,
    IFaiTechnoEligibility,
    IPtoVerticalStructure
} from '@interfaces/api/fai.interface';
import { AddressModel } from '@models/cart/address.model';
import { FaiStepsModel } from '@models/fai/fai-steps';
import { OrderModel } from '@models/order/order.model';
import { DialogRef, DialogService } from '@ngneat/dialog';
import { PortabilityService } from '@services/checkout/portability.service';
import { FaiFunnelService } from '@services/fai/fai-funnel.service';
import { OrderService } from '@services/order.service';
import { PrismeLoggerService } from '@services/prisme-logger.service';
import { SalesUserService } from '@services/sales-user.service';
import { PartialAddress, WetoFunnelProps } from 'src/app/common-modules/interfaces/widget/weto-funnel-props.interface';
import {
    FAI_CART_TECHNO,
    FAI_CART_TYPE,
    FAI_ELIGIBILITY_STATUS,
    FAI_TECHNO,
    FAI_WIDGET_ROUTES,
    PTO_ACCESS_STATUS
} from '../../constants/fai';
import { FtthWetoMapper } from '@components/fai-widget/eligibility/models/ftth.mapper.model';

@Injectable({
    providedIn: 'root'
})
export class FaiEligibilityService {

    public currentCart: FaiCartModel;
    public currentWidget: ALLOWED_TYPES;
    public faiLoginsAndVoipsData: FaiLoginsAndVoipsModel;
    public faiSteps: FaiStepsModel;
    public previousRouteName: string;

    constructor(
        private faiRepository: FaiRepository,
        private funnelService: FaiFunnelService,
        private configService: ConfigurationService,
        private customerService: CustomerService,
        private orderService: OrderService,
        private dialogService: DialogService,
        protected prismeLoggerService: PrismeLoggerService,
        public salesUserService: SalesUserService,
        private portabilityService: PortabilityService,
        private router: Router
    ) {
        const storedData = FaiStorage.getItem(FaiStorage.KEYS.FAI_CART);
        if (storedData){
            this.currentCart = plainToClass(FaiCartModel, storedData);
            this.currentWidget = this._getFaiWidgetEndpoint(this.currentCart.type);
        }
        const storedStepsData = FaiStorage.getItem(FaiStorage.KEYS.FAI_WIDGET_STEPS);
        this.faiSteps = storedStepsData ? plainToClass(FaiStepsModel, storedStepsData) : new FaiStepsModel();

        this.router.events
            .pipe(filter(event => (event instanceof NavigationEnd || event instanceof NavigationStart)))
            .subscribe({
                next: (event: Event) => {
                    const currentPathUrl: string = this.router.url.split('?')[0].split('#')[0];

                    if (event instanceof NavigationStart) {
                        if (currentPathUrl && currentPathUrl.includes('fai')) {
                            this.previousRouteName = currentPathUrl.split('/').pop();
                        }
                    }

                    if (event instanceof NavigationEnd && this.previousRouteName !== this.faiSteps.getPreviousStep()?.toLowerCase()) {
                        this.updateFaiSteps(this.previousRouteName);
                    }
                }
            });
    }

    public updateFaiCart(data: any, step: FAI_WIDGET_ROUTES): void {
        const cartId = this._initializeCart(data);
        switch (step) {
            case FAI_WIDGET_ROUTES.HOUSING:
                this._handleHousingStep(data, cartId);
                break;
            case FAI_WIDGET_ROUTES.PTO:
                this._handlePtoStep(data, cartId);
                break;
            case FAI_WIDGET_ROUTES.END:
                this._handleEndStep(data, cartId);
                break;
            default:
                console.log('Nothing happens here');
        }
        this._save();
    }

    public updateFaiSteps(step: string): void {
        this.faiSteps.setStep(step);
        FaiStorage.setItem(FaiStorage.KEYS.FAI_WIDGET_STEPS, classToPlain(this.faiSteps));
    }

    public buildAddressRestoreFromRecovery(address: FaiAddressModel): WetoFunnelProps {

        if (!address) {
            return null;
        }

        const { city, postal, street, street_number, complement } = address;
        return {
            partialAddresss: {
                zipCode: postal,
                city: city.libelle,
                street: street.libelle,
                streetNumber: street_number,
            },
            bypass: false,
            address: {
                zipCode: postal,
                inseeCode: city.code,
                rivoliCode: street.code,
                streetNumber: street_number,
                extension: (complement as IFaiComplement)?.libelle
            }
        };
    }

    public buildAddressRestoreFromData(bypass: boolean = false): WetoFunnelProps {
        if (!this.currentCart?.eligibility) {
            return null;
        }
        const { city, postal, street, street_number, complement } = this.currentCart.eligibility.installationAddress;
        return {
            partialAddresss: {
                zipCode: postal,
                city: city.libelle,
                street: street.libelle,
                streetNumber: street_number,
            },
            bypass,
            address: {
                zipCode: postal,
                inseeCode: city.code,
                rivoliCode: street.code,
                streetNumber: street_number,
                extension: (complement as IFaiComplement)?.libelle
            }
        };
    }

    public buildAddressRestoreFromSalesForce(faiSalesForceAddress: AddressModel): PartialAddress {
        if (!faiSalesForceAddress) {
            return;
        }

        return {
            zipCode: faiSalesForceAddress?.postalCode,
            city: faiSalesForceAddress?.city,
            street: faiSalesForceAddress?.street,
            streetNumber: faiSalesForceAddress?.streetNumber
        };
    }

    public checkDuplicateOrder(faiLogement: FaiLogementModel | null, customerId?: string): void {
        const customerIdPerson = customerId || this.getCustomerIdPerson();
        if (customerIdPerson) {
            this.orderService.getFaiOrders(customerIdPerson).subscribe((orders: OrderModel[]) => {
                const duplicateOrderFai = this.hasDuplicateOrderFai(orders, faiLogement);
                if (duplicateOrderFai) {
                    const dialogRef: DialogRef<any> = this.dialogService.open(
                        CancelOrderModalComponent,
                        {
                            data: {
                                orderId: duplicateOrderFai,
                                customerId: customerIdPerson
                            },
                            closeButton: false,
                            enableClose: false
                        }
                    );
                    dialogRef.afterClosed$.subscribe((results: { [key: string]: boolean }) => {
                        this.prismeLoggerService.sendDataToPrisme('Doublons de commandes',
                            {continue: results?.continue, orderId: duplicateOrderFai, puid: customerIdPerson}
                        );
                        if (!results?.continue) {
                            this.salesUserService.closeCall();
                        }
                    });
                }
            });
        }
    }

    public hasDuplicateOrderFai(orders: OrderModel[], faiLogement: FaiLogementModel|null): boolean | string {
        if (!this.currentCart) {
            return false;
        }

        const keyToCompare = [
            'codeInsee',
            'codeVoie',
            'numeroVoie'
        ];

        let currentAddress: FaiLogementModel|IFaiAdresseIFainstallation = faiLogement;

        if (!currentAddress && this.currentCart?.techno) {
            currentAddress = new FaiLogementModel({});
            if (this.configService.data_refconf?.fai?.widget?.active) {
                const adresseTmp = this.currentCart?.eligibility.rawApiData.address;
                currentAddress.codeInsee = adresseTmp.city.INSEECode;
                currentAddress.codeVoie = adresseTmp.street.rivoliCode;
                currentAddress.numeroVoie = adresseTmp.streetNumber.number;
            } else {
                if (this.currentCart?.techno === FAI_CART_TECHNO.MONO_TECHNO) {
                    currentAddress = this.currentCart?.eligibility?.webshopData?.resultat?.adresseInstallation;
                } else {
                    currentAddress = this.currentCart?.selectedLogement;
                }
            }
        }

        const res = orders.filter(
            (order: OrderModel) => {
                let isIdentical = true;
                keyToCompare.forEach((key: string) => {
                    if (currentAddress[key] !== order?.fai?.logement[key]) {
                        isIdentical = false;
                    }
                });
                return isIdentical;
            }
        );

        if (res.length > 0) {
            return res[0].id;
        }
        return false;
    }

    // @TODO: V1 to remove on complete merge
    public reserveLogin(): void{
        this._save();
    }

    // @TODO: V1 to remove on complete merge
    public reserveVoip(): void{
        this._save();
    }

    // @TODO: V1 to remove on complete merge
    public selectVoip(voip: string): void{
        this.currentCart.selectedVoip = voip;
        this._save();
    }

    // @TODO: V1 to remove on complete merge
    public selectLogin(login: string, selected: boolean = false): void{
        this.currentCart.selectedLogin = login;
        this.currentCart.isCustomLogin = selected;
        this._save();
    }

    public getConvergenceForOffers(gencodes: string[]): Observable<{[index: string]: boolean}>{
        return this.faiRepository.getConvergenceForOffers(gencodes);
    }

    // @TODO: V1 to remove on complete merge
    public resolve(): Observable<FaiLoginsAndVoipsModel> {
        if (this.configService.data_refconf?.fai?.widget?.active) {
            return;
        }
        this.resetCartStatus();
        return this.faiRepository
            .getFaiPortabilityParams(this.currentCart.id, this.customerService.customer)
            .pipe(
                tap((data: FaiLoginsAndVoipsModel) => this.faiLoginsAndVoipsData = data)
            );
    }

    // @TODO: V1 to remove on complete merge
    public getAndSaveLogement(
        search: FaiAddressModel|string,
        options?: { status?: boolean; techno?: FAI_CART_TECHNO; panier?: Observable<FaiCartModel> }): Observable<FaiLogementModel[]>{
        return (options?.panier || this.faiRepository.createCart(
            search instanceof FaiAddressModel ? FAI_CART_TYPE.ADDRESS : FAI_CART_TYPE.ND,
            options?.techno
        )).pipe(
            tap((cart)=> {
                this.currentCart = cart;
                this.currentCart.setSearch(search);
                this.currentCart.setStatus(options?.status);
            }),
            mergeMap((cart) => (!options?.panier && search instanceof FaiAddressModel) ? this.funnelService.playAllFunnelSteps(
                this.currentCart.searchAddress.getAddressModel(),
                cart,
                this.currentCart.techno
            ).pipe(map(()=>cart)) : of(cart)),
            mergeMap((cart)=> this.faiRepository.getLogements(cart)),
            tap((logements)=>{
                this.currentCart.setLogements(logements);
            }),
            tap(()=>{
                this._save();
            })
        );
    }

    // @TODO: V1 to remove on complete merge
    public getCustomerIdPerson(): string {
        return this.customerService?.customer?.idPerson;
    }

    public getCustomerService(): CustomerService {
        return this.customerService;
    }

    // @TODO: V1 to remove on complete merge
    public getLogementByName(
        name: string
    ): Observable<FaiLogementModel[]>{
        return this.faiRepository.getLogements(this.currentCart, name);
    }

    // @TODO: V1 to remove on complete merge
    public getAndSaveEligibility(
        logement: FaiLogementModel,
        verticalStructure?: FaiVerticalStructureModel
    ): Observable<FaiEligibilityModel>{
        let cartObs: Observable<FaiCartModel>;
        if ((this.currentCart.selectedLogement && logement && !verticalStructure) ||
            (verticalStructure && this.currentCart.selectedVerticalStructure && this.currentCart.techno !== FAI_CART_TECHNO.MONO_TECHNO)){
            cartObs = this.currentCart.type === FAI_CART_TYPE.ND ? this.faiRepository.createCart(
                this.currentCart.type,
                this.currentCart.techno
            ) : this.funnelService.getFaiAddress(this.currentCart.searchAddress.getAddressModel(),this.currentCart.techno).pipe(
                mergeMap(()=>this.funnelService.faiCart),
            );
            cartObs = this.currentCart.techno === FAI_CART_TECHNO.MONO_TECHNO ? cartObs.pipe(
                delayWhen((cart)=>this.getPto(this.currentCart.searchAddress,{panier:of(cart)})),
            ) : cartObs.pipe(
                delayWhen((cart)=>this.getAndSaveLogement(this.currentCart.searchAddress || this.currentCart.searchNumber,{
                    panier: of(cart),
                    status: this.currentCart.option.status
                })),
                delayWhen((cart)=> this.faiRepository.getEligibility(
                    cart,
                    logement,
                    null
                ).pipe(mergeMap((faiEligibility) => this._completeInstallationAddress(logement, faiEligibility)))),
            );
            cartObs = cartObs.pipe(
                tap((cart)=>{
                    cart.setSearch(this.currentCart.searchAddress || this.currentCart.searchNumber);
                    cart.setStatus(this.currentCart?.option.status);
                    cart.logements = this.currentCart.logements;
                    this.currentCart = cart;
                }));
        } else {
            cartObs = of(this.currentCart);
        }
        return cartObs.pipe(
            mergeMap(cart => this.faiRepository.getEligibility(
                cart,
                logement,
                verticalStructure
            ).pipe(mergeMap((faiEligibility) => this._completeInstallationAddress(logement, faiEligibility)))),
            tap((faiEligibility)=>{
                this.currentCart.selectedVerticalStructure = plainToClass(FaiVerticalStructureModel, verticalStructure);
                this.currentCart.selectedLogement = logement;
                this.currentCart.setEligibility(faiEligibility);
                this._save();
            })
        );
    }

    // @TODO: V1 to remove on complete merge
    public getPto(
        address: FaiAddressModel,
        options?: {panier?: Observable<FaiCartModel>}
    ): Observable<FaiVerticalStructureModel[]> {
        return (options?.panier || this.faiRepository.createCart(
            FAI_CART_TYPE.ADDRESS,
            FAI_CART_TECHNO.MONO_TECHNO
        )).pipe(
            map((cart: FaiCartModel) =>this.currentCart = cart),
            tap(() => this.currentCart.setSearch(address)),
            mergeMap(() => this.faiRepository.getPto(
                this.currentCart.id,
                address,
                FAI_CART_TECHNO.MONO_TECHNO
            ).pipe(
                tap((verticalStructures) => {
                    this.currentCart.verticalStructures = verticalStructures;
                    this._save();
                })
            ))
        );
    }

    public getForecastUrlInfos(techno: FaiTechnoModel): string {

        const customer: CustomerProGpDetailsModel = this.customerService.customer;
        const eligibility = this.currentCart;
        const urlParams: {[key: string]: any} = {
            stOrigine: 'MAGENTO',
            typeClient: 'id_PU',
            idClient: customer?.idPerson ?? '',
            civilite: `${customer?.gender?.slice(0, 1)}${customer?.gender?.substring(1).toLowerCase()}` ?? '',
            nom: customer?.lastname ?? '',
            prenom: customer?.firstname ?? '',
            mailContact: customer?.email ?? '',
            numContact: customer?.phone ?? '',
            numeroVoie: eligibility.searchAddress?.street_number ?? '',
            extension: eligibility.searchAddress?.complementLibelle ?? '',
            voie: eligibility.searchAddress?.street?.libelle ?? '',
            codePostal: eligibility.searchAddress?.postal ?? '',
            codeInsee: eligibility.searchAddress.city.code,
            commune: eligibility.searchAddress.city.libelle,
            appFtth: techno.type === FAI_TECHNO.FTTH ? 1 : 0,
            app4gbox: techno.type === FAI_TECHNO.BOX_4G ? 1 : 0,
        };
        const urlData: string = Object.keys(urlParams).map(key => `${key}=${urlParams[key]}&`).join('');
        const encodedUrl: string = encodeURI(urlData.substring(0, urlData.length - 1));
        return this.configService.data_refconf.origin.concat('/prise-interet?', encodedUrl);
    }

    // @TODO: V1 to remove on complete merge
    public reset(): void{
        FaiStorage.clear();
        this.currentCart = null;
    }

    // @TODO: V1 to remove on complete merge
    public resetCartStatus(): void {
        this.currentCart.updated = false;
        this._save();
    }

    public resetCartPortability(): void {
        this.currentCart.selectedLogin = null;
        this.currentCart.isCustomLogin = null;
        this.currentCart.selectedVoip = null;
        this.portabilityService.clear();
        this._save();
    }

    private _save(): void{
        FaiStorage.setItem(FaiStorage.KEYS.FAI_CART, classToPlain(this.currentCart));
    }

    // @TODO: V1 to remove on complete merge
    private _completeInstallationAddress(logement: FaiLogementModel, faiEligibility: FaiEligibilityModel): Observable<FaiEligibilityModel> {
        if (faiEligibility?.installationAddress?.city?.code || faiEligibility?.installationAddress?.street?.code) {
            return of(faiEligibility);
        }
        if (!!logement?.logementNonTrouve) {
            return of(faiEligibility);
        }
        const installationAddress: FaiAddressModel = new FaiAddressModel({
            postal: logement.codePostal,
            city: {
                libelle: logement.commune,
                code: logement.codeInsee,
            },
            street: {
                libelle: logement.voie,
                code: logement.codeVoie
            },
            street_number: logement.numeroVoie,
            complement: logement.extension,
        });
        faiEligibility.installationAddress = installationAddress;
        faiEligibility.webshopData.resultat.adresseInstallation = {
            codeInsee: logement.codeInsee,
            codePostal: logement.codePostal,
            codeVoie: logement.codeVoie,
            commune: logement.commune,
            escalier: logement?.escalier,
            etage: logement?.etage,
            listeRouge: logement.listeRouge,
            ndi: logement.ndi,
            numeroVoie: logement.numeroVoie,
            voie: logement.voie,
            porte: logement.porte,
            logo: logement.logo,
            residence: logement.residence,
            batiment: logement.batiment,
            extension: logement.extension
        };
        return of(faiEligibility);
    }

    private _setSelectedPto(ftthInfo: FtthWetoMapper): void {

        if (ftthInfo?.pto) {
            this.currentCart.selectedVerticalStructure = {
                ...this.currentCart.verticalStructures?.find(
                    (v: FaiVerticalStructureModel) => v.ptos.includes(ftthInfo.pto)
                ),
                ptoSelected: ftthInfo.pto,
                presencePto: true,
                dateOuvertureCommerciale: ftthInfo.commercialOpeningDate
            } as FaiVerticalStructureModel;
        } else {
            const { building: batiment, floor: etage, stair: escalier } = ftthInfo?.verticaleStructure;
            this.currentCart.selectedVerticalStructure = new FaiVerticalStructureModel({
                batiment,
                etage,
                escalier,
                ptoSelected: null,
                presencePto: ftthInfo?.ptoStatut === PTO_ACCESS_STATUS.INCONNU,
                dateOuvertureCommerciale: ftthInfo?.commercialOpeningDate
            });
        }
    }

    @bind
    private _createLogementModel(housing: IFaiHousing): FaiLogementModel {
        return new FaiLogementModel({
            codePostal: this.currentCart.searchAddress?.postal,
            codeInsee: this.currentCart.searchAddress?.city?.code,
            codeVoie: this.currentCart.searchAddress?.street?.code,
            codeNumeroVoie: this.currentCart.searchAddress?.streetNumberCode,
            numeroVoie: this.currentCart.searchAddress?.street_number,
            extension: typeof this.currentCart.searchAddress?.complement === 'string' ?
                this.currentCart.searchAddress?.complement : this.currentCart.searchAddress?.complement.libelle,
            escalier: housing.stairs,
            etage: housing.stage,
            porte: housing.gate,
            logo: housing.logo,
            residence: housing.residence,
            batiment: housing.building,
            codeLogement: '',
            voie: this.currentCart.searchAddress?.street?.libelle,
            voieTechnique: '',
            commune: this.currentCart.searchAddress?.city?.libelle,
            typeVoie: '',
            numeroDepartement: this.currentCart.searchAddress?.postal.substring(0, 2),
            ndStatut: housing.lineStatus,
            listeRouge: housing.redlist,
            nomTitulaire: housing.redlist ? housing.predecessorNameMask : housing.predecessorName,
            ndi: housing.number,
            logementNonTrouve: housing.isCdl
        });
    }

    private _createAddressModel(data: IAddressFull): FaiAddressModel {
        return new FaiAddressModel({
            postal: data.zipCode,
            city: {code: data.city.INSEECode, libelle: data.city.label },
            street: {code: data.street.rivoliCode, libelle: data.street.label },
            street_number: data?.streetNumber?.number,
            complement: { libelle: data.streetNumber.complement, code: null }
        });
    }

    private _createEligibilityModel(data: any): FaiEligibilityModel {
        return new FaiEligibilityModel({
            ...this._createTechnoModelsData(data),
            installationAddress: this._createAddressModel(data.address ?? data.addressFull),
            isCDL: !!data?.housing?.selected?.isCdl,
            webshopData: null,
            rawApiData: data
        });
    }

    private _createTechnoModel(apiData: Partial<IFaiTechnoEligibility>, forceKoStatus: boolean = false,
                               forceEligForecast: boolean = false): FaiTechnoModel {
        return new FaiTechnoModel({
            status: apiData?.status && !forceKoStatus ? FAI_ELIGIBILITY_STATUS.OK : FAI_ELIGIBILITY_STATUS.NOK,
            type: apiData.technoType,
            ...(apiData?.debitUpMax ? { debitUpMax: apiData.debitUpMax } : {}),
            ...(apiData?.debitUpMin ? { debitUpMin: apiData.debitUpMin } : {}),
            ...(apiData?.debitDownMax ? { debitDownMax: apiData.debitDownMax } : {}),
            ...(apiData?.debitDownMin ? { debitDownMin: apiData.debitDownMin } : {}),
            allowForecast: {
                eligible: (!!apiData?.forecast && !apiData?.status) || forceEligForecast,
                ...(apiData?.forecast?.date ? { date: apiData.forecast.date } : {})
            },
            offres: apiData?.offers,
            technologiesPon: apiData.ponTechnologies,
            ...(apiData?.homeZone ? { homeZone: apiData.homeZone } : {}),
            collectionNetwork: apiData?.collectionNetwork
        });
    }

    private _createTechnoModelsData(data: Record<string, any>): Record<string, FaiTechnoModel> {
        const { adsl, box4g: box_4g, box5g: box_5g, ftth, vdsl } = data;
        const technos: { [key: string]: IFaiTechnoEligibility } = {
            adsl, box_4g, box_5g, ftth, vdsl
        };
        return Object.keys(technos).reduce((acc, curr) => {
            let forceKoStatus = false;
            let forceEligForecast = false;
            if ((curr === FAI_TECHNO.ADSL || curr === FAI_TECHNO.VDSL) && ftth?.status) {
                forceKoStatus = true;
            }
            if ((curr === FAI_TECHNO.BOX_4G || curr === FAI_TECHNO.BOX_5G) && !technos[curr]?.status) {
                forceEligForecast = true;
            }
            const techno = this._createTechnoModel({ ...technos[curr] as any, technoType: curr }, forceKoStatus, forceEligForecast);
            return { ...acc, [curr]: techno };
        }, {});
    }

    private _createVerticalStructure(data: IPtoVerticalStructure): FaiVerticalStructureModel[] {
        return data.stairs[0]?.stages.map(stage => new FaiVerticalStructureModel({
            batiment: data.building,
            escalier: data.stairs[0].stair,
            etage: stage.floor,
            presencePto: false,
            ptos: stage.ptos.map(p => p.pto),
            ptoSelected: '',
            dateOuvertureCommerciale: null,
            idPtoObligatoire: stage.ptoRequired,
        }));
    }


    private _getFaiWidgetEndpoint(type: FAI_CART_TYPE): ALLOWED_TYPES {
        const mapping = {
            [FAI_CART_TYPE.ND]: ALLOWED_TYPES.NUMBER,
            [FAI_CART_TYPE.ADDRESS]: ALLOWED_TYPES.ADDRESS
        };
        return mapping[type];
    }



    private _initializeCart(data: any): string {
        const { cartId: cartIdOnELig, id: cartIdOnPtoStep } = data;
        const cartId = cartIdOnELig ?? cartIdOnPtoStep;
        if (this.currentCart?.id !== cartId) {
            this.currentCart = null;
        }
        return cartId;
    }

    private _handleHousingStep(data: any, cartId: string): void {
        const { dslInfo: { housingList }, addressFull } = data;
        this.currentCart = new FaiCartModel(
            this.currentWidget === ALLOWED_TYPES.NUMBER ? FAI_CART_TYPE.ND : FAI_CART_TYPE.ADDRESS,
            FAI_CART_TECHNO.MULTI_TECHNO,
            cartId
        );
        this.currentCart.setSearch(data?.nd ?? this._createAddressModel(addressFull));
        this.checkDuplicateOrder(addressFull);
        this.currentCart.setLogements(housingList?.map(this._createLogementModel));
        this.currentCart.setEligibility(this._createEligibilityModel(data));
    }

    private _handlePtoStep(data: any, cartId: string): void {
        if (!this.currentCart) {
            const { addressFull: address } = data;
            this.currentCart = new FaiCartModel(
                this.currentWidget === ALLOWED_TYPES.NUMBER ? FAI_CART_TYPE.ND : FAI_CART_TYPE.ADDRESS,
                FAI_CART_TECHNO.MULTI_TECHNO,
                cartId
            );
            this.currentCart.setSearch(this._createAddressModel(address));
            this.currentCart.verticalStructures = this._createVerticalStructure(data.buildings[0]);
            this.checkDuplicateOrder(address);
        }
        this.currentCart.setEligibility(this._createEligibilityModel(data));
    }

    private _handleEndStep(data: any, cartId: string): void {
        if (!this.currentCart) {
            this.currentCart = new FaiCartModel(
                this.currentWidget === ALLOWED_TYPES.NUMBER
                    ? FAI_CART_TYPE.ND : FAI_CART_TYPE.ADDRESS, FAI_CART_TECHNO.MULTI_TECHNO, cartId
            );
            this.currentCart.setLogements([]);
            this.currentCart.setSearch(data?.searchData?.nd ? data?.searchData?.nd : this._createAddressModel(data.address));
        }
        if (this.currentCart?.logements?.length) {
            this.currentCart.selectedLogement = this._createLogementModel(data?.housing?.selected);
        }
        this.resetCartPortability();
        this.currentCart.setEligibility(this._createEligibilityModel(data));
        if (data.ftth.status) {
            this._setSelectedPto(data.ftth);
        }
    }


}

