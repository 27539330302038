<div class="columns is-centered" *ngIf="scoring?.globalErrors?.length">
    <div class="column is-5" data-cy="partial-scoring">
        <div class="is-divider has-background-grey-lighter"></div>
        <div class="notification is-small has-body is-warning">
            <div class="icon">
                <i class="tri-exclamation-circle"></i>
            </div>
            <div class="body">
                <ng-container *ngFor="let error of scoring?.globalErrors">
                    <p class="title" data-cy="partial-scoring-user-feedback" [innerHTML]="error"></p>
                </ng-container>
            </div>
        </div>
        <div class="is-divider has-background-grey-lighter"></div>
    </div>
</div>