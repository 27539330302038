<div class="box is-flat">
    <div class="box-content has-background-grey-lighter">
        <!-- block customer -->
        <h5 class="title is-size-4" [data-cy]="'info-title'">
            <span *ngIf="customerPro"> SIREN : {{customerPro.siren }} / RAISON SOCIALE : {{customerPro.socialReason }}</span>
            <span *ngIf="customerGp">{{customerGp.gender }}. {{customerGp.lastname }} {{customerGp.firstname }} - né(e) le : {{customerGp.birthDate | date:'dd/MM/yyyy' }} </span>
        </h5>
        <!-- block contract -->
        <div class="columns is-6 is-multiline">
            <div class="column is-12">
                <div *ngFor="let contract of contracts" data-cy="billingAccount">
                    <hr class="is-divider">
                    <span>Référence N°: {{ contract.noReference }} -</span>
                    <span class="has-text-weight-semibold"> {{ contract.ligneMarche }} </span>
                    <span *ngIf="contract.msisdn"> {{ contract.msisdn }} -</span>
                    <span *ngIf="contract.offrePrincipale.libelle"> {{ contract.offrePrincipale.libelle }} - </span>
                    <span *ngIf="customer.email"> {{ customer.email }} - </span><span class="has-text-weight-semibold is-uppercase">{{ contract.statut }}</span>
                </div>
            </div>
            <div class="column is-12">
                <div class="field has-text-centered" *ngIf="isBtnValid">
                    <button [disabled]="isClientLockByScoring || isLoading" type="button" data-cy="customer-info-submit" class="button is-small is-primary" (click)="nextStep()">Sélectionner</button>
                </div>
            </div>
        </div>
    </div>
</div>
