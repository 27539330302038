import {
    Component,
    ComponentRef,
    Input,
    OnChanges,
    OnDestroy,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { Equipment, Fai, Plan, ProductFactory, Sowo } from '@bytel/bytel-sales';
import { DialogService } from '@ngneat/dialog';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { Subscription } from 'rxjs';
import { TELESALES_URLS } from 'src/app/constants/urls';

import { CartComponentMapping } from '../cart-component-mapping';
import { QuoteMixedModalComponent } from '../quote-mixed-modal/quote-mixed-modal.component';
import { EquipmentComponent } from './equipment/equipment.component';
import { ProductBlockDirective } from './product-block.directive';
import { SubscriptionComponent } from './subscription/subscription.component';
import { OpportunityService } from '@services/opportunity.service';

@Component({
    selector: 'tlv-product-block',
    templateUrl: './product-block.component.html',
    styleUrls: ['./product-block.component.scss']
})
export class ProductBlockComponent implements OnChanges, OnDestroy {

    @Input() product: Equipment | Plan;
    @Input() wallType: string;
    @Input() quoteIndex: number;

    public editUrl: string;
    public isFai: boolean;
    public principalSubscription: Plan;
    public principalEquipment: Equipment;

    @ViewChild(ProductBlockDirective, { static: true }) productHost: ProductBlockDirective;

    private _subscriptions: Subscription[] = [];

    constructor(
        private cartService: CartTeleSalesService,
        private opportunityService: OpportunityService,
        private dialogService: DialogService,
        private router: Router
    ) { }


    public ngOnChanges(): void {
        this.isFai = ProductFactory.Is(this.product, Fai);
        const viewContainerRef: ViewContainerRef = this.productHost.viewContainerRef;
        viewContainerRef.clear();
        const componentRef: ComponentRef<EquipmentComponent | SubscriptionComponent> =
            viewContainerRef.createComponent(CartComponentMapping.getComponent(this.product));
        componentRef.instance.product = this.product;
        this.principalSubscription = this.cartService.cartModel.getQuote(this.quoteIndex)?.getPrincipalProduct<Plan>('Plan');
        this.principalEquipment = this.cartService.cartModel.getQuote(this.quoteIndex)?.getPrincipalProduct<Equipment>('Equipment');
        this.editUrl = '/' + this._getWallUrl(this.principalSubscription, this.principalEquipment);
    }

    public edit(): void {
        if (this.cartService.isQuoteMixed() && ProductFactory.Is(this.principalSubscription, Sowo)) {
            const refDialog = this.dialogService.open(QuoteMixedModalComponent, {
                data: {
                    isEditSimo: true
                },
                closeButton: false,
                enableClose: false
            });
            this._subscriptions.push(refDialog.afterClosed$.subscribe((skip) => {
                if (skip) {
                    this.remove();
                }
            }));
        } else {
            this.setIdOpportunityToEdit();
            this.router.navigate([this.editUrl]);
        }
    }

    public setIdOpportunityToEdit(): void {
        const currentOpportunityDad = this.opportunityService.opportunity.opportunityDad;
        const quoteIdOpportunity = this.cartService.cartModel.getQuote(this.quoteIndex)?.idOpportunity;
        if (quoteIdOpportunity) {
            this.opportunityService.onChangeOpportunityDad(currentOpportunityDad.idCase, quoteIdOpportunity);
        }
    }

    public remove(): void {
        this.cartService.removeProduct(this.product, this.quoteIndex).subscribe();
        this.cartService.clearQuotes();
        this.router.navigate(['panier']);
    }

    public ngOnDestroy(): void {
        this._subscriptions.forEach(sub => sub?.unsubscribe());
    }

    protected _getWallUrl(principalSubscription: Plan, principalEquipment: Equipment): string {
        let wallUrl: string;
        switch (this.wallType) {
            case 'equipment':
                wallUrl = TELESALES_URLS[principalEquipment.wallurl];
                break;
            case 'subscription':
                let subscriptionWallurl = principalSubscription.wallurl;
                if (ProductFactory.Is(principalSubscription, Plan) && principalSubscription.data.banking_offer) {
                    subscriptionWallurl = 'prompto';
                }
                wallUrl = TELESALES_URLS[subscriptionWallurl];
                break;
            default:
                wallUrl = TELESALES_URLS.phone_simple;
                break;
        }
        return wallUrl;
    }
}
