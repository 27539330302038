import { Injectable } from '@angular/core';
import { ConfigurationService } from '@common-modules';
import { Edp, IEdp, Phone, QuoteModel } from '@bytel/bytel-sales';
import { IFundingResponse, IPropositionsCommerciale } from '@interfaces/funding.interface';
import { FUNDING_METHODS, PAYMENT_DEFAULT_METHOD, PAYMENT_METHODS } from '@interfaces/payment.interface';
import { MainCartModel } from '@models/cart/main-cart.model';
import { FundingMethod } from '@models/payment-method';
import { FundingRepository } from '@repositories/funding.repository';
import { PaymentMethodsRepository } from '@repositories/payment-methods.repository';
import { forkJoin, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class PaymentMethodsService {

    constructor(
        private configService: ConfigurationService,
        private fundingRepository: FundingRepository,
        private paymentMethodsRepository: PaymentMethodsRepository,
    ) {}

    public updateFundingMethodsFromMOCK(cartModel: MainCartModel): Observable<FundingMethod> {
        if (!cartModel.cartId) {
            return of({ modesPaiement: [PAYMENT_METHODS.CB, PAYMENT_METHODS.HYBRIDE] }).pipe(
                mergeMap(({ modesPaiement }) => this.getFundingMethodInfoModePaiement(cartModel, modesPaiement)),
                map((fundingMethods: FundingMethod[]) => this.setFundingMethod(cartModel, fundingMethods))
            );
        }
        return of(null);
    }

    public updateFundingMethodsFromAPI(cartModel: MainCartModel): Observable<FundingMethod> {
        return this.fundingRepository.getFundingMethod(cartModel).pipe(
            mergeMap((response: IFundingResponse) => this.getFundingFromPaymentMethod(cartModel, response)),
            map((fundingMethods: FundingMethod[]) => this.setFundingMethod(cartModel, fundingMethods))
        );
    }

    public setFundingMethod(cartModel: MainCartModel,fundingMethods: FundingMethod[] ): FundingMethod | null {
        cartModel.availableFundingMethods = fundingMethods;
        if (!cartModel.fundingMethod || !this._AlReadyAvailableMethod(cartModel.fundingMethod,fundingMethods)) {
            const quote: QuoteModel = cartModel.getQuote();
            const storedFunding = quote?.forms?.funding;
            if (storedFunding && fundingMethods.find((funding) => funding.type === storedFunding.type)) {
                return storedFunding;
            } else {
                cartModel.setFundingMethod(this.getDefaultFundingMethod(fundingMethods,cartModel)?.type || '');

                return this.getDefaultFundingMethod(fundingMethods,cartModel);
            }
        } else {
            cartModel.fundingMethod = fundingMethods.find(f=>cartModel.fundingMethod.type === f.type);
        }
    }

    public getFundingMethodInfoModePaiement(cartModel: MainCartModel, paymentMethods: string[]): Observable<FundingMethod[]> {
        const groupedFundings = this.parseFundingmethod(paymentMethods, cartModel);
        return forkJoin(Object.keys(groupedFundings).map((key) => this.paymentMethodsRepository.getFundingMethodInfo(
            {
                url: this.configService.data_refconf.checkout.cofidis.url,
                partnerId: this.configService.data_refconf.checkout.cofidis.id_partner
            },
            key,
            groupedFundings[key],
            cartModel.totals?.daily.final
        )));
    }


    public getFundingFromPaymentMethod(cartModel: MainCartModel, fundingResponse: IFundingResponse): Observable<FundingMethod[]> {

        const propositionsCommerciale: {EDP?: IPropositionsCommerciale; CASH?: IPropositionsCommerciale} = {};
        let paymentMethods = fundingResponse.modesDeFinancement.flatMap(({modesDePaiement}) => modesDePaiement.map(({type}) => type));
        paymentMethods = [...new Set(paymentMethods)];

        if (!!cartModel.getQuote().getProductByType(Phone)){
            const gencodePhone = cartModel.getQuote().getProductByType(Phone).gencode;
            const propositionsCommercialeEdp = fundingResponse.parcours
                .map(({produits}) => produits.filter(({gencode}) => gencode === gencodePhone ).shift())
                ?.flatMap((produit) => produit?.modesDeFinancementProduits?.filter(({type}) => type === 'EDP'))
                ?.flatMap((modesDeFinancement) => modesDeFinancement?.propositionsCommerciales.shift());
            if (propositionsCommercialeEdp) {
                propositionsCommerciale.EDP = propositionsCommercialeEdp.shift();
            }
        }

        const propositionsCommercialeCash = fundingResponse.modesDeFinancement
            .filter(({type}) => type === 'COMPTANT')
            .flatMap(({propositionsCommerciales}) =>  propositionsCommerciales.shift());

        propositionsCommerciale.CASH = propositionsCommercialeCash.shift();

        const groupedFundings = this.parseFundingmethod(paymentMethods, cartModel);

        return forkJoin(Object.keys(groupedFundings)
            .filter(key => !!propositionsCommerciale[key])
            .map((key) => this.fundingRepository.getFundingMethodInfo(
                key,
                groupedFundings[key],
                cartModel.totals?.daily.final,
                propositionsCommerciale[key]
            )));
    }

    public parseFundingmethod(paymentMethods: string[], cartModel: MainCartModel): { [index: string]: string[] } {

        const complexeArrayFundings = paymentMethods.map((paymentMethod: string) => Object.entries(FUNDING_METHODS)
            .filter(([key, value]) => value.methods.includes(paymentMethod) && !this._isFundingMethodEdp(key, cartModel))
            .map(([key]) => ({[key]: paymentMethod})));

        const groupedFundings: { [index: string]: string[] } = [].concat(...complexeArrayFundings).reduce((ac, a) => {
            const key = Object.keys(a)[0];
            ac[key] = ac[key] || [];
            ac[key].push(Object.values(a)[0]);
            return ac;
        }, {});

        return groupedFundings;

    }

    public getDefaultFundingMethod(fundingMethods: FundingMethod[],cart: MainCartModel): FundingMethod | null {
        return cart.getQuote().getProductByType(Edp) ?
            fundingMethods.find(fundingMethod=>fundingMethod.type === 'EDP') :
            fundingMethods.find((fundingMethod)=> fundingMethod.type === PAYMENT_DEFAULT_METHOD);
    }

    private _AlReadyAvailableMethod(method: FundingMethod,methodsAvailable: FundingMethod[]): boolean{
        return methodsAvailable.some((methodAvailable)=>methodAvailable.type === method.type);
    }

    private _isFundingMethodEdp(key: string, cartModel: MainCartModel): boolean {
        return key === 'EDP' && (!cartModel.promotions.canHaveEdp() || !new Edp({} as IEdp).canAdd(cartModel.getQuote()));
    }

}

