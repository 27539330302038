import { Component, Input, OnInit } from '@angular/core';
import {
    Equipment,
    Fai,
    Faim,
    FaimPremium,
    Option,
    OPTION_LOCKED_ID,
    Plan,
    ProductFactory,
    QuoteModel,
    Sensation,
} from '@bytel/bytel-sales';
import { MainCartModel } from '@models/cart/main-cart.model';
import { ProductRepository } from '@repositories/product.repository';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { OptionsAvailableService } from '@services/checkout/options-available.service';
import { PortabilityService } from '@services/checkout/portability.service';
import { EngagementLabelPipe } from 'src/app/pipes/engagement-label.pipe';

@Component({
    selector: 'tlv-subscriptions-summary',
    templateUrl: './subscriptions-summary.component.html',
    styleUrls: ['./subscriptions-summary.component.scss']
})
export class SubscriptionsSummaryComponent implements OnInit {
    @Input() showSmallerView: boolean;
    @Input() public cartModel: MainCartModel;
    @Input() public isMultiQuote: boolean;

    public equipment: Equipment;
    public plan: Plan;
    public fai: Fai = null;
    public productLabel: string;
    public isSensation: boolean = false;
    public isFai: boolean = false;
    public isPro: boolean = false;
    public planFeatures: string[] = [];
    public pcOption: Option;

    private _cartModel: MainCartModel;

    constructor(
        protected cartService: CartTeleSalesService,
        protected portabilityService: PortabilityService,
        protected optionsAvailableService: OptionsAvailableService,
        protected engagementLabelPipe: EngagementLabelPipe
    ) {
    }

    public ngOnInit(): void {
        this._cartModel = this.cartModel ?? this.cartService.cartModel;
        this._init();
    }

    public getFaiLabel(fai: Fai): string {
        if (this.isPro && fai.proLabel) {
            return fai.proLabel;
        }
        return fai?.technology ? fai.name : fai?.label;
    }

    private _init(): void {
        const quote: QuoteModel = this._cartModel.getQuote();
        this.equipment = quote.getPrincipalProduct<Equipment>('Equipment');
        this.plan = quote.getPrincipalProduct<Plan>('Plan');
        if (this.isMultiQuote) {
            this.plan = quote.getPrincipalProduct('Fai');
        }
        this.isFai = this.plan && ProductFactory.Is(this.plan, Fai);
        this.isPro = this.plan.isPro ||
        quote.getProductByType('Option')?.gencode === ProductRepository.OPTION_PRO_GENCODE;
        const options: Option[] = quote.getProductsByType<Option>('Option');
        this.pcOption = options.find(op => [
            ...this.optionsAvailableService.getParentalControlGencode('basic'),
            ...this.optionsAvailableService.getParentalControlGencode('premium')
        ].includes(op.gencode));

        if (!!this.plan){
            this.isSensation = ProductFactory.Is(this.plan, Sensation);
            this.productLabel = this.isPro ? this.plan.proLabel : this.plan.label;
            this.planFeatures = !this.isFai ? this._getPlanFeatures(this.plan, quote) : [];
            if (this.plan instanceof Fai){
                this.fai = this.plan;
            }
            if (this.pcOption) {
                this.planFeatures.push(this.pcOption.name);
            }
        }
    }

    private _getPlanFeatures(plan: Plan, quote: QuoteModel): string[] {
        const planFeatures: string[] = [];
        planFeatures.push(this.engagementLabelPipe.transform(this.plan.data.obligation));
        if (!(ProductFactory.Is(plan, Faim) || ProductFactory.Is(plan, FaimPremium))) {
            planFeatures.push(!!this.portabilityService.portability ? 'Portabilité ancien numéro' : 'Nouveau numéro de portable');
        }
        if (plan.data?.data_envelope !== 'no-limit') {
            planFeatures.push(plan.data?.data_envelope);
        }
        if (!!quote.getProductByGencode(OPTION_LOCKED_ID)) {
            planFeatures.push('Version bloquée');
        }
        return planFeatures;
    }

}
