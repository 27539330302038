import { Injectable } from '@angular/core';
import {CartModel, PromotionModel, PromotionsModel, TYPE_MARKETING} from '@bytel/bytel-sales';
import { DisplayAccessoryModel } from '@models/cart/display-accessory.model';
import { PromotionDetails } from '@models/promotion-details.model';
import { PromotionRepository } from '@repositories/promotion.repository';
import { Observable } from 'rxjs';
import {map} from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
})
export class PromotionsService {

    constructor(private promotionRepo: PromotionRepository) {}

    public getCouponPromotionForCart(cart: CartModel, code: string): Observable<PromotionModel>{
        return this.promotionRepo.getCouponPromotionForCart(cart, code);
    }


    public getAllCouponPromotionForCart(cart: CartModel): Observable<string[]>{
        return this.promotionRepo.getAllCouponPromotionForCart(cart);
    }

    public getPromotions(cart: CartModel): Observable<PromotionsModel>{
        return this.promotionRepo.getPromotions(cart);
    }

    public getPromotionDetails(uuid: string, id: string): Observable<PromotionDetails>{
        return this.promotionRepo.getPromotionDetails(uuid, id);
    }

    public getPromotionsByTypeMarketing( cart: CartModel, type: TYPE_MARKETING): Observable<PromotionsModel> {
        return this.getPromotions(cart)
            .pipe(
                map((promotions: PromotionsModel) => {
                    const automatic = promotions.automatic.filter((
                        promotion: PromotionModel) => promotion.typesMarketing.includes(type));
                    const manual = promotions.manual.filter((
                        promotion: PromotionModel) => promotion.typesMarketing.includes(type));
                    const incitation = promotions.incitation.filter((
                        promotion: PromotionModel) => promotion.typesMarketing.includes(type));

                    return new PromotionsModel(automatic, manual, incitation);
                })
            );
    }


    public preloadPromotions(cart: CartModel, gencode: string[], indexQuote?: number): Observable<{[index: string]: PromotionsModel}>{
        return this.promotionRepo.preload(cart, gencode, indexQuote);
    }

    public getPromotionsCart(cart: CartModel): Observable<PromotionsModel>{
        return this.promotionRepo.getPromotionsForCart(cart);
    }

    public GetPromotionsForProduct(product: DisplayAccessoryModel, promotions: PromotionsModel): PromotionsModel {
        const couponPromo: PromotionModel = promotions.coupon;
        return new PromotionsModel(
            promotions.automatic.filter((p) => this.isApplicable(product, p)),
            promotions.manual.filter((p) => this.isApplicable(product, p)),
            promotions.incitation.filter((p) => this.isApplicable(product, p)),
            (couponPromo && this.isApplicable(product, couponPromo)) ? couponPromo : null
        );
    }

    public isApplicable(product: DisplayAccessoryModel,promotion: PromotionModel): boolean{
        return promotion.relevantProducts.includes(product.gencode);
    }
}
