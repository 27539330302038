import {
    IGlobalScoring,
} from '@interfaces/scoring.interface';
import { Model } from './model';
import {
    Resultat
} from '@bytel/pt-ihm-api-egide-controle-risque-vente-demander-offres-autorisees/dist/models/components/schemas/Resultat';

export class ScoringModel extends Model {

    public detailed: Resultat[];
    public global: IGlobalScoring;
    public avc: number;

    constructor(data: Partial<ScoringModel>){
        super(data);
    }

    public get detailedErrors(): string[] {
        // return this._getErrorMessages(this.detailed.controls);
        return this._getErrorMessagesSalesRisk(this.global.incompatibilitySalesRisk);
    }

    public get globalErrors(): string[] {
        return this._getErrorMessagesSalesRisk(this?.global?.incompatibilitySalesRisk);
    }

    public isEdpIncompatible(): boolean {
        return !!this.global?.incompatibilitySalesRisk?.edp;
    }

    public isClientBlockedByScoring(): boolean {
        if (!this.global?.incompatibilitySalesRisk) {
            return false;
        }

        const incompatibilities: string[] = Object.keys(this.global?.incompatibilitySalesRisk);
        const compatibilities: string[] = Object.keys(this.global?.compatibilitySales);
        return incompatibilities.length > 5 ||
            (!incompatibilities.includes('edp') && incompatibilities.length === 5) && !compatibilities.length;// ||
        // This is a temporary rule which must be changed once the API is ready
        //            !!this.detailed.controls?.controleEcheancier;
    }

    private _getErrorMessagesSalesRisk(node: {[key: string]: Resultat}): string[] {
        return node ? Object.keys(node)
            .filter(control => !!node[control]?.message)
            .map(control => node[control]?.message) : [];
    }
}
