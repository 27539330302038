import { Injectable } from '@angular/core';
import { Oauth2ResourcesService } from '@common-modules';
import {
    CartModel,
    IPromotionRepository, Plan,
    Product, ProductFactory, PromotionModel, PromotionOdrModel, PromotionsModel, QuoteModel, REDUCTION_TYPE, TYPE_MARKETING
} from '@bytel/bytel-sales';
import { IPromotion } from '@interfaces/api/promotion.interface';
import { IPromotionDetails, IPromotionsCartV2, IPromotionV2 } from '@interfaces/api/promotions-v2.interface';
import { CustomerDetailsModel } from '@models/customer/customer-details.model';
import { PortabilityService } from '@services/checkout/portability.service';
import { CustomerService } from '@services/customer/customer.service';
import { forkJoin, from, Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import bind from '../helper/decorators/bind';
import { SalesApiHelper } from '../helper/sales-api.helper';
import { FaiEligibilityService } from '@services/fai/fai-eligibility.service';
import { PromotionDetails } from '@models/promotion-details.model';

@Injectable({
    providedIn: 'root'
})
export class PromotionRepository implements IPromotionRepository {

    public cache: {[index: string]: PromotionsModel} = {};

    constructor(private oauth2Ressource: Oauth2ResourcesService,
                private customerService: CustomerService,
                private portabilityService: PortabilityService,
                private readonly faiEligibility: FaiEligibilityService) {
    }

    public preload(cart: CartModel,products: string[]|Product[], indexQuote?: number): Observable<{[index: string]: PromotionsModel}>{
        // @ts-ignore
        const gencodes: string[] = products.map((g)=>g instanceof Product ? g.gencode : g);
        return this.getPromotions(
            cart,
            gencodes,
            indexQuote
        );
    }

    public getAllCouponPromotionForCart(cart: CartModel): Observable<string[]>{
        return this.oauth2Ressource
            .useSalesApi()
            .ventes()
            .panier()
            .codesPromotionnels()
            .post({
                ...SalesApiHelper.GetCartGenericParams(cart)
            }).pipe(
                map<{promotions: any[]},string[]>(
                    (data) => data.promotions.map((promo)=>promo.coupons[0])
                )
            );
    }

    public getCouponPromotionForCart(cart: CartModel, code: string): Observable<PromotionModel> {
        return this.oauth2Ressource
            .useSalesApi()
            .ventes()
            .panier()
            .codesPromotionnels()
            .post({
                ...SalesApiHelper.GetCartGenericParams(cart),
                coupon: code
            }).pipe(
                map<{ promotions: IPromotion[]; isEligibleEdp: boolean},PromotionModel>((data)=>
                    data.promotions.length ?
                        this._createCouponPromotionModel(data.promotions[0],data.isEligibleEdp)
                        : null
                ),
                catchError(()=>of(null))
            );
    }

    public getPromotionsForCart(cart: CartModel): Observable<PromotionsModel> {
        if (cart?.quotes[cart.currentQuoteIndex]?.products.length){
            return this.getPromotions(cart);
        } else {
            return of(new PromotionsModel([],[],[]));
        }
    }

    public getPromotionDetails(promoId: string, id: string): Observable<any> {
        return this.oauth2Ressource
            .useSalesApi()
            .addCache()
            .detailsPromotion(promoId)
            .get()
            .pipe(
                catchError(()=>of(null)),
                map((promoDetails: IPromotionDetails)=>({...promoDetails, id})),
                map(this._createPromotionDetailsModel)
            );
    }

    public getPromotions(cart: CartModel): Observable<PromotionsModel>;
    public getPromotions(
        cart: CartModel,
        gencodes: string[],
        indexQuote: number
    ): Observable<{[index: string]: PromotionsModel}>;
    public getPromotions(cart: CartModel, gencodes: string[] = [], indexQuote: number = 0
    ): Observable<{[index: string]: PromotionsModel} | PromotionsModel> {
        let keyCache = this._getKeyCache(cart,indexQuote);
        if (this.cache[keyCache] && gencodes.length === 0){
            return of(new PromotionsModel(
                this.cache[keyCache].automatic,
                this.cache[keyCache].manual,
                this.cache[keyCache].incitation,
            ));
        }
        return this.getPromotionFromApi(cart,indexQuote).pipe(
            tap(this._addPromotionV2ToCache(cart,indexQuote)),
            map((data)=> {
                keyCache = this._getKeyCache(cart,indexQuote);
                return gencodes.length ? data : new PromotionsModel(
                    this.cache[keyCache]?.automatic,
                    this.cache[keyCache]?.manual,
                    this.cache[keyCache]?.incitation,
                );
            })
        );
    }

    public getPromotionFromApi(cart: CartModel, indexQuote: number, gencodes: string[] = []
    ): Observable<{[index: string]: PromotionsModel}> {
        if (!cart.getQuote()){
            throw new Error('You need quote to get promotion');
        }

        const gencodesPerPages: string[][] = gencodes.length ? gencodes.reduce((acc, cur, i) => {
            const idx = Math.floor(i / 150);
            const page = acc[idx] || (acc[idx] = []);
            page.push(cur);
            return acc;
        }, []) : [null];

        return forkJoin(
            gencodesPerPages.map(gencodesArray => from(this.oauth2Ressource
                .ventes()
                .panier()
                .eligibilitePromotionnelle()
                .useSalesApi()
                .retry({
                    count: 3,
                    delay: (error, retryCount) => {
                        if (error.status === 401) {
                            return throwError(() => error);
                        }
                        return of(retryCount * 500);
                    },
                    resetOnSuccess: true
                })
                .addCache()
                .post(this._getBodyPromoDto(cart, gencodesArray, indexQuote))
                .pipe(
                    map((carts: IPromotionsCartV2[]) => carts.reduce((acc,cartPromotions)=>({
                        ...acc,
                        [cartPromotions.avecLeProduit] : this._getPromotionsOfApiCart(cartPromotions)
                    }), {}))
                )
            ).pipe(
                catchError(()=>
                    of(gencodesArray.reduce((acc,gencode)=>({
                        ...acc,
                        [gencode]:new PromotionsModel([],[],[])
                    }),{}))
                )
            )
            )
        ).pipe(
            catchError(()=>of([])),
            map(promotionsProducts => promotionsProducts.reduce((acc,promotions)=>({...acc,...promotions}), []))
        );
    }

    @bind
    private _addPromotionV2ToCache(cart: CartModel, indexQuote: number): (promotions: {[index: string]: PromotionsModel}) => void{
        return (promotions: {[index: string]: PromotionsModel}): void => {
            for (const gencode in promotions){
                const keyCache = this._getKeyCache(cart,indexQuote,gencode !== 'undefined' ? [gencode] : []);
                this.cache[keyCache] = promotions[gencode];
            }
        };
    }

    private _getPromotionsOfApiCart(cart: IPromotionsCartV2): PromotionsModel {

        const arrayOfProducts = cart.parcours.flatMap(parcour => parcour.produits);
        return arrayOfProducts.reduce(
            (acc, product) => new PromotionsModel(
                this._mergePromotions(acc.automatic,
                    product.promotions.automatiques.map(p => this._createPromotionv2Model(p, [product.gencode]))),
                this._mergePromotions(acc.manual,
                    product.promotions.manuelles.map(p => this._createPromotionv2Model(p, [product.gencode]))),
                this._mergePromotions(acc.incitation,
                    product.promotions.incitations.map(p => this._createPromotionv2Model(p, [product.gencode])))
            ), new PromotionsModel([], [], []));
    }

    private _getKeyCache(cart: CartModel,indexQuote: number,gencodes: string[] = []): string {
        const EXCLUDE_GENCODE = ['EPD0000000'];
        let productGencodes: string[] = gencodes.concat(cart.getAllProducts()
            .filter(p=>p.gencode && !this._ingoredByCache(p))
            .map((p)=>p.gencode.toString()));
        productGencodes = productGencodes.filter(g=>!EXCLUDE_GENCODE.includes(g));
        productGencodes.sort();
        const promoManual = cart.promotions.manual.map(p=>p.id).join('-');
        // eslint-disable-next-line max-len
        return `${this.customerService?.customer?.idPerson}-${promoManual}-${productGencodes}-${this._getContextId(cart)}_${this.portabilityService?.portability?.rioCode || false}-${indexQuote}`;
    }

    private _ingoredByCache(product: Product): boolean{
        const ignoredProductType = ['Activation','FaiBox'];
        return ignoredProductType.some(typeClass => ProductFactory.Is(product,typeClass));
    }

    private _createCouponPromotionModel(promotionData: IPromotion,isEligibleEdp: boolean): PromotionModel{
        const promo = this._createPromotionModel(promotionData);
        promo.isEligibleEdp = !!isEligibleEdp;
        return promo;
    }

    @bind
    private _createPromotionModel(promotionData: IPromotion): PromotionModel | PromotionOdrModel{
        const data = {
            id : promotionData.id,
            addProduct: promotionData.produitAAjouter,
            name: promotionData.nom,
            label: promotionData.libelle,
            type: promotionData.type,
            description: promotionData.description,
            // @ts-ignore
            amount: +promotionData.valeurRemise || +promotionData.montantRemise,
            reductionType: promotionData.typeRemise === 'POURCENTAGE' ? REDUCTION_TYPE.POURCENT : REDUCTION_TYPE.FIX,
            duration : promotionData.duree,
            dates : promotionData.dates,
            code : promotionData.coupons?.length ? promotionData.coupons[0] : null,
            message : promotionData.message,
            relevantProducts : promotionData.produits.map(p=>p.gencode)
        };
        return promotionData.condition.odr ? new PromotionOdrModel({...data, pdfLink : ''}) : new PromotionModel(data);
    }

    @bind
    private _createPromotionv2Model(promotionData: IPromotionV2,products: string[]): PromotionModel | PromotionOdrModel{
        const data = {
            id : promotionData.id,
            addProduct: promotionData.produitAAjouter,
            name: promotionData.nom,
            label: promotionData.libelle,
            type: promotionData.type,
            description: promotionData.description,
            amount: +promotionData.valeurRemise,
            reductionType: promotionData.typeRemise === 'POURCENTAGE' ? REDUCTION_TYPE.POURCENT : REDUCTION_TYPE.FIX,
            duration : promotionData.duree,
            dates : promotionData.dates,
            code : promotionData.coupons?.length ? promotionData.coupons[0] : null,
            message : {...promotionData.message, ...{portability_other: promotionData.proprietesSupplementaires?.blocPorta}} ,
            relevantProducts : products,
            typesMarketing : promotionData.proprietesSupplementaires?.typesMarketing as TYPE_MARKETING[] || [],
            fanion : promotionData.proprietesSupplementaires?.fanion,
            odr: promotionData.odr,
            aUnePortabilite: promotionData.aUnePortabilite
        };
        return promotionData.odr ? new PromotionOdrModel({...data, pdfLink : ''}) : new PromotionModel(data);
    }

    private _mergePromotions(promotionsA: PromotionModel[],promotionsB: PromotionModel[]): PromotionModel[]{
        for (const promoB of promotionsB){
            const PromoA = promotionsA.find(p=>p.id === promoB.id);
            if (PromoA){
                PromoA.relevantProducts = promoB.relevantProducts.concat(PromoA.relevantProducts);
            } else {
                promotionsA.push(promoB);
            }
        }

        return promotionsA;
    }

    private _getQuoteDto(cart: CartModel): any {
        return cart.quotes.map((quote,indexQuote)=>({
            type : cart.quotes[indexQuote].context.id,
            idVirtuel: indexQuote,
            promotionsManuelles: [],
            produits: this._getProductDto(quote)
        }));
    }

    private _getProductDto(quote: QuoteModel): any {
        return quote.products.filter(p=>SalesApiHelper.IsApiProduct(p)).map((product,indexProduct)=>({
            promotionsManuelles: product.promotions.manual.map(p=>p.id),
            gencode: product.gencode,
            idVirtuel: indexProduct,
            ...(product instanceof Plan && this.portabilityService?.portability?.rioCode ?
                {rio: this.portabilityService.portability.rioCode} :
                {}),
            ...(this.faiEligibility?.currentCart?.eligibility?.installationAddress?.postal ?
                {codePostalFaiInstallation: this.faiEligibility.currentCart.eligibility.installationAddress.postal} :
                {})
        }));
    }

    private _getBodyPromoDto(cart: CartModel, gencodesArray: string[], indexQuote): any {
        return {
            ...(this.customerService.customer?.idPerson ? {idPU: +this.customerService.customer.idPerson} : {}),
            ...(this.customerService.customer ?
                {typeClient:  (this.customerService.customer instanceof  CustomerDetailsModel ?  'GP' : 'PRO')} :
                {typeClient : 'GP'}
            ),
            panier: {
                promotionsManuelles: [],
                parcours: this._getQuoteDto(cart)
            },
            ...(gencodesArray?.length ? {produits: gencodesArray.map((gencode)=>({
                gencode,
                ajouterDansLeParcours: indexQuote ?? 0,
                ...(this.faiEligibility?.currentCart?.eligibility?.installationAddress?.postal ?
                    {codePostalFaiInstallation: this.faiEligibility.currentCart.eligibility.installationAddress.postal} :
                    {})
            }))} : {produits: []})
        };
    }

    private _getContextId(cart: CartModel): string {
        const isMultiQuotes = cart.quotes.length > 1 && cart.quotes.every(quote => !!quote.products.length);
        if (isMultiQuotes) {
            return 'ACQUISITIONMIXTE';
        }
        return cart.getQuote(cart.currentQuoteIndex).context.id;
    }

    @bind
    private _createPromotionDetailsModel(promotionDetailsData: IPromotionDetails): PromotionDetails {
        const [caracteristiques] = promotionDetailsData.caracteristiques;
        return {
            id: Number(promotionDetailsData.id),
            uuid: promotionDetailsData.identifiant,
            name: promotionDetailsData.libelle,
            commericialName: promotionDetailsData.libelleCommercial,
            amount: promotionDetailsData?.remise?.montant,
            duration: promotionDetailsData?.remise?.duree,
            amountType: promotionDetailsData?.remise?.type,
            promoType: promotionDetailsData.type,
            linesType: caracteristiques?.type,
            caracteristicType: caracteristiques?.valeur,
            isSubjectToReservation: promotionDetailsData.remise.estSousReserve,
        };
    }


}
