import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { PrismeLoggerService } from '@services/prisme-logger.service';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { Fai, FaimUnlimited, ProductFactory } from '@bytel/bytel-sales';
import { filter } from 'rxjs/operators';

@Injectable()
export class ClickListenerService {
    private renderer: Renderer2;
    private nbClickOnScheme: number = 0;

    public constructor(
        private rendererFactory: RendererFactory2,
        private router: Router,
        private prismeLogger: PrismeLoggerService,
        private cartlvService: CartTeleSalesService,

    ) {
        this.renderer = this.rendererFactory.createRenderer(null, null);
    }

    public init(): void {
        this.nbClickOnScheme = 0;
        this.renderer.listen('document', 'click', () => {
            this.nbClickOnScheme++;
        });

        // Listen for navigation events
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
            if (event.urlAfterRedirects.startsWith('/order-success')) {
                this.sendClickCountsLogs();
            }
        });
    }

    private sendClickCountsLogs(): void {
        let typeParcours = '';
        const plan: Fai = this.cartlvService.cartModel.getQuote().getPrincipalProduct<Fai>('Plan');
        if (this.cartlvService.isQuoteMixed()){
            typeParcours = 'MIXED';
        } else if (this.cartlvService.hasFaiInCart() || ProductFactory.Is(plan, FaimUnlimited)){
            typeParcours = 'FAI';
        } else {
            typeParcours = 'MOBILE';
        }
        if (this.nbClickOnScheme > 0) {
            this.prismeLogger.sendDataToPrisme(
                'ClickListener',
                {
                    nbClick: this.nbClickOnScheme,
                    typeParcours,
                    technology : typeParcours === 'FAI' ? plan.technology : undefined
                }
            );
        }
    }

}
