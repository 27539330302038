export enum CONTRACT_STATUS {
    ACTIVE = 'ACTIF',
    SUSPENDED = 'SUSPENDU',
    DEACTIVATED = 'DESACTIVE',
    TERMINATED = 'RESILIE'
}

export enum OFFER_TYPE {
    PREMIUM = 'PREMIUM'
}
export enum RemiseConvergenteType {
    MULTILIGNE = 'MULTILIGNE',
    PACKFAMILLE_MOBILE_PALLIER_1 ='PACKFAMILLE_MOBILE_PALLIER_1',
    PACKFAMILLE_MOBILE_PALLIER_2 = 'PACKFAMILLE_MOBILE_PALLIER_2',
    PACKFAMILLE_MOBILE_PALLIER_3 ='PACKFAMILLE_MOBILE_PALLIER_3',
    PACK_FAMILLE_FIXE = 'PACK_FAMILLE_FIXE',
    PACK_FAMILLE_MOBILE = 'PACK_FAMILLE_MOBILE'
}

export interface ContexteOperateur {
    personneUnique: PersonneUnique;
    commandesCommercialesEnCours?: CommandeCommerciale[];
    contratsServiceNonResilies?: ContratService[];
}
export interface CommandeCommerciale {
    idCommande: string;
    offresPrincipales?: OffrePrincipale[];
}
export interface OffrePrincipale {
    idOffre: string;
    ligneMarche: string;
    ligneMarcheConvergent: string;
    niveauGammeConvergent?: string;
}
export interface ContratService {
    idContrat: string;
    offrePrincipale: OffrePrincipale;
}
export interface PersonneUnique {
    idPersonne:  string;
    remisesPotentielles?: RemisePotentielle[];
}

export interface RemisePotentielle {
    type: string;
}
