import { Model } from '../model';

export interface BillingContract {
    codeCauseChgtStatut: string;
    msisdn: string;
    noReference: string;
    idTitulaire: string;
    idPfs: string;
    statut: string;
    dateCreation: Date|string;
    datePremiereActivation: Date;
    ligneMarche: string;
    offrePrincipale: {
        noOffre: string;
        libelle: string;
    };
    noVoip: string;
    loginBbox: string;
}

export class BillingAccountModel extends Model {
    id: string;
    noCompte: string;
    statut: string;
    identifiantFonctionnelClient: string;
    adresseDeclaree: {
        numero: string;
        rue: string;
        ville: string;
        codePostal: string;
        codePays: string;
    };
    contratsPayes?: BillingContract[];

    constructor(data: Partial<BillingAccountModel>){
        super(data);
    }

}
