
<ng-template #message let-data="data">
    <span [innerHTML]="data.message"></span>
</ng-template>
<div class="columns is-multiline is-marginless is-centered" data-cy="scoring-modal">
    <div class="is-divider column is-12 has-background-white"></div>
    <div class="column is-12 has-text-centered">
        <p class="title is-3">Gestion du scoring</p>
    </div>
    @for (res of ref.data.scoringError; track $index) {
        @if (isQuoteMixed()) {
            @switch (res.type) {
                @case ("FAI") {
                    <div class="column is-8 has-text-centered has-text-weight-bold">
                        <p data-cy="scoring-modal-message">Parcours Fixe : <ng-container *ngTemplateOutlet="message; context: {data: res}"></ng-container></p>
                    </div>

                }
                @case ("FNB") {
                    <div class="column is-8 has-text-centered has-text-weight-bold">
                        <p data-cy="scoring-modal-message">Parcours Mobile : <ng-container *ngTemplateOutlet="message; context: {data: res}"></ng-container></p>
                    </div>
                }
            }
        } @else if (res.message) {
            <div class="column is-8 has-text-centered has-text-weight-bold">
                <p data-cy="scoring-modal-message">Parcours : <ng-container *ngTemplateOutlet="message; context: {data: res}"></ng-container></p>
            </div>
        }
    }
        @if (hasEdpKo()) {
            <div class="column is-8 has-text-centered">
                <p>
                    <span bytelTranslate="checkout.scoring.edp_warning">Si vous décidez de continuer le paiement sans EDP les montants de votre facture seront modifiés de la manière suivante :</span>
                </p>
                <div class="is-divider has-background-white"></div>
            </div>
            <div class="column is-12 is-paddingless">
                <div class="columns is-centered">
                    <div class="column is-8">
                        <tlv-ticket [cartModel]="cartModel"></tlv-ticket>
                    </div>
                </div>
                <div class="buttons is-centered">
                    <button data-cy="modal-action-close" class="button is-primary" [disabled]="isLoading" (click)="endCall()">
                        <span bytelTranslate="back_to_home">{{ isLoading ? 'Traitement en cours...' : 'Terminer session'}}</span>
                    </button>
                    <button data-cy="modal-action-removeEdp" class="button is-primary" [disabled]="isLoading" (click)="removeEdpAndPay()">
                        <span bytelTranslate="checkout.scoring.remove_edp">
                            {{ isLoading ? 'Traitement en cours...' : 'Continuer le paiement sans EDP' }}
                        </span>
                    </button>
                </div>
            </div>
        } @else {
            <div class="column is-8">
            <div class="buttons is-centered ">
                <button data-cy="modal-action-close" class="button is-primary" [disabled]="isLoading" (click)="endCall()">
                    <span bytelTranslate="back_to_home">{{ isLoading ? 'Traitement en cours...' : 'Terminer session'}}</span>
                </button>
                <button data-cy="modal-action-removeEdp" class="button is-info" [disabled]="isLoading" (click)="closeModalAndRedirectCart()">
                        <span bytelTranslate="checkout.scoring.remove_edp">
                            {{ isLoading ? 'Traitement en cours...' : 'Modifier le Panier' }}
                        </span>
                </button>
            </div>
            </div>

        }
    <div class="column is-8 "></div>
</div>
