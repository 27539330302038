import { Injectable } from '@angular/core';
import { Product, ProductFactory } from '@bytel/bytel-sales';
import { ScoringModalComponent } from '@components/checkout/step/payment/scoring-modal/scoring-modal.component';
import { MainCartModel } from '@models/cart/main-cart.model';
import { ScoringModel } from '@models/scoring.model';
import { DialogRef, DialogService } from '@ngneat/dialog';
import { ScoringStorage } from '@repositories/storages/scoring.storage';
import { CustomerService } from '@services/customer/customer.service';
import { plainToClass } from 'class-transformer';
import { Observable, Subject } from 'rxjs';
import {SalesRiskControlRepositoryRepository} from '@repositories/sales-risk-control.repository';
import {
    Resultat
} from '@bytel/pt-ihm-api-egide-controle-risque-vente-demander-offres-autorisees/dist/models/components/schemas/Resultat';
import {IGlobalscoringTypes} from '@interfaces/scoring.interface';

@Injectable({
    providedIn: 'root'
})
export class ScoringService {

    protected static INCOMPATIBLE_RULES: {[key: string]: string[]} = {
        basic: ['Simo','Sowo'],
        premium: ['Sensation'],
        faim: [
            'Faim',
            'FaimPremium'
        ],
        faimUnlimited: ['FaimUnlimited'],
        fai: ['Fai']
    };

    protected static readonly EDP_GLOBAL: string = 'global.edp';
    public scoring: ScoringModel;
    public scoring$: Observable<ScoringModel>;
    private _scoringSubject$: Subject<ScoringModel> = new Subject<ScoringModel>();

    constructor(
        private salesRiskControlRepositoryRepository: SalesRiskControlRepositoryRepository,
        private customerService: CustomerService,
        private dialogService: DialogService
    ){
        this.scoring$ = this._scoringSubject$.asObservable();
        if (!this.customerService.customer?.idPerson) {
            this.clear();
        }
        const storedScoring: ScoringModel = plainToClass(ScoringModel, ScoringStorage.getItem<ScoringModel>(ScoringStorage.KEYS.SCORING));
        if (storedScoring) {
            this.scoring = storedScoring;
        }
    }

    public getScoring(cartModel: MainCartModel, personId?: string): Observable<ScoringModel> {
        return this.salesRiskControlRepositoryRepository.checkScoring(
            personId ? personId : this.customerService.customer?.idPerson,
            cartModel.cartId?.toString()
        );
    }


    public set(data: ScoringModel): void {
        this.scoring = data;
        this._save(data);
        this._scoringSubject$.next(data);
    }

    public clear(): void {
        this.scoring = null;
        this.salesRiskControlRepositoryRepository.clear();
        this._scoringSubject$.next(null);
    }


    public getScoringErrorByProductType(productType: IGlobalscoringTypes): Resultat {
        const incompatibleScoringFromStorage = this._getScoringFromStorage();
        const incompatibilityRule: Resultat = incompatibleScoringFromStorage?.global?.incompatibilitySalesRisk[productType];
        return incompatibilityRule ?? null;
    }

    public getScoringError(productItem: Product): Resultat {

        const matchingRule = Object.keys(ScoringService.INCOMPATIBLE_RULES)
            .find((rule) => !!ScoringService.INCOMPATIBLE_RULES[rule]
                .find((product: string) => ProductFactory.Is(productItem, product, true)));
        const incompatibleScoringFromStorage = this._getScoringFromStorage();
        const incompatibilityRule: Resultat = incompatibleScoringFromStorage?.global?.incompatibilitySalesRisk[matchingRule];
        return incompatibilityRule ?? null;
    }

    public openScoringModal(scoringRules: Resultat[]): DialogRef<any> {
        return this.dialogService.open(ScoringModalComponent, {
            closeButton:false,
            size: 'md',
            data: {
                scoringError: scoringRules,
                continueButton: true,
            }
        });
    }

    private _getScoringFromStorage(): ScoringModel {
        return plainToClass(ScoringModel, ScoringStorage.getItem<ScoringModel>(ScoringStorage.KEYS.SCORING));
    }

    private _save(data: ScoringModel): void {
        this.salesRiskControlRepositoryRepository.saveScoring(data);
    }

}
