import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, ReactiveFormsModule} from '@angular/forms';
import {PromotionModel} from '@bytel/bytel-sales';
import {Observable, of, Subscription} from 'rxjs';
import {CartTeleSalesService} from '@services/checkout/cart-telesales.service';
import {MainCartModel} from '@models/cart/main-cart.model';
import {NgForOf, NgIf} from '@angular/common';
import {PriceComponent} from '../../../../../trg-components/price/price.component';
import {SharedModules} from '../../../../../shared/shared.module';


@Component({
    selector: 'tlv-family-pack',
    standalone: true,
    imports: [
        NgForOf,
        NgIf,
        PriceComponent,
        ReactiveFormsModule,
        SharedModules
    ],

    templateUrl: './family-pack.component.html',
    styleUrl: './family-pack.component.scss'
})
export class FamilyPackComponent implements OnInit, OnDestroy{

    @Input() manualPromos!: PromotionModel[];
    @Input() quoteIndex: number;
    @Input() automaticPromotionsSum: number;
    @Output() promoChange = new EventEmitter();
    public promotionInCart: PromotionModel;
    public isPromoLoading = false;
    public isFai = false;

    public familyPackForm: FormGroup ;


    private _cart: MainCartModel;
    private _promotionsInFai: PromotionModel[];
    private _promotionsInAquisitionMobile: PromotionModel[];
    private _subscriptions: Subscription[] = [];

    constructor(private readonly cartService: CartTeleSalesService,
                private formBuilder: FormBuilder) {}

    public ngOnInit(): void{
        this.familyPackForm = this.formBuilder.group({
            familyPack: [null]
        });
        this._cart = this.cartService.cartModel;
        this.isFai = this._cart.getQuote(this.quoteIndex)?.isAcquisitionFix();
        if (this.isFai) {
            this._promotionsInFai = this.manualPromos;
        } else {
            this._promotionsInAquisitionMobile = this.manualPromos;
        }
        const promotion: PromotionModel[] = this._cart.promotions.manual;
        this.promotionInCart = promotion.find(promoInCart =>
            this.manualPromos.find(manualPromo => manualPromo.id === promoInCart.id)
        );
        const gencodes = this._cart.getQuote(this.quoteIndex)?.products.map(product => product.gencode) || [];
        const isPromoApplied = this.promotionInCart?.relevantProducts?.every(product => gencodes.includes(product)) || false;
        if (this.promotionInCart && isPromoApplied) {
            this.familyPackForm.get('familyPack').setValue(this.promotionInCart.id);
        } else {
            this.familyPackForm.get('familyPack').setValue(false);
        }


        this._subscriptions.push(
            this.familyPackForm.get('familyPack').valueChanges
                .subscribe(() => this._onPromotionChange(this.familyPackForm.get('familyPack').value))
        );
    }

    public ngOnDestroy(): void {
        this._subscriptions.forEach(sub => sub?.unsubscribe());
    }

    public addPromotion(promo: PromotionModel): void {
        this._subscriptions.push(
            this.cartService.getCartService().addManualPromotion(this._cart, promo).subscribe(() => {
                this.promoChange.emit();
                this.isPromoLoading = false;
            })
        );
    }

    public removePromotion(): Observable<void> {
        const promoToRemove = this.findPromosToRemove();
        if (this._cart.promotions.manual.length) {
            if (promoToRemove.length) {
                return this.cartService.getCartService().removeManualPromotion(
                    this._cart,
                    promoToRemove[0]);
            }
        }
        return of(null);
    }

    private findPromosToRemove(): PromotionModel[] {
        return this._cart.promotions.manual.reduce((acc, element) => {
            if ((this.isFai ? this._promotionsInFai : this._promotionsInAquisitionMobile)
                .map(p => p.data.id)
                .includes(element.data.id)) {
                acc.push(element);
            }
            return acc;
        }, []);
    }

    private _onPromotionChange(value: number): void {
        this.isPromoLoading = true;
        if (value){
            this._subscriptions.push(
                this.removePromotion().subscribe(
                    ()=>this.addPromotion(this.manualPromos.find((promo)=>promo.id === value))
                )
            );
        } else {
            this.removePromotion().subscribe(() => {
                this.promoChange.emit();
                this.isPromoLoading = false;
            });
        }
    }
}
